import React, { createContext, useCallback, useState } from 'react'
import { getLocale, setLocale as setLocaleString } from '../../constants/locales'

export const Context = createContext({})

const AppStateContext = ({ children }) => {
  const [locale, setLocale] = useState(getLocale())
  const [walletModal, setWalletModal] = useState(false)

  const handleActiveLocale = useCallback((val) => {
    setLocaleString(val)
    setLocale(val)
  }, [setLocale])

  return (
    <Context.Provider value={{ locale, setLocale: handleActiveLocale, walletModal, setWalletModal }}>
      {children}
    </Context.Provider>
  )
}

export default AppStateContext
