import ethereumLogoUrl from '../assets/images/ethereum-logo.png';
import polygonMaticLogo from '../assets/svg/polygon-matic-logo.svg';
import bscLogo from '../assets/svg/binance.svg';

import { SupportedChainId } from './chains';

export const NetworkType = {
  L1: 0,
  L2: 1,
};

export const CHAIN_INFO = {
  [SupportedChainId.MAINNET]: {
    networkType: NetworkType.L1,
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  [SupportedChainId.POLYGON]: {
    networkType: NetworkType.L1,
    explorer: 'https://polygonscan.com/',
    label: 'Polygon',
    logoUrl: polygonMaticLogo,
    nativeCurrency: {
      name: 'Polygon Matic',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  [SupportedChainId.BSC]: {
    networkType: NetworkType.L1,
    explorer: 'https://bscscan.com/',
    label: 'BSC',
    logoUrl: bscLogo,
    nativeCurrency: {
      name: 'Polygon Matic',
      symbol: 'BNB',
      decimals: 18,
    },
  },
};
