import AppHeader, { AppWrapper } from '../../components/AppView';
import { Link } from 'react-router-dom';
import { ArrowLeft, Plus } from 'react-feather';
import { ButtonEmpty } from '../../components/Button';
import { Trans } from '@lingui/macro';
import AppBody from '../AppBody';
import { useCallback, useEffect, useState } from 'react';
import PoolsAddModal from './components/PoolsAddModal';
import { useMiniPool } from '../../hooks/useContract';
import { MINI_POOL_ADDRESS } from '../../constants/address';
import PoolItems from './components/PoolItems';
import Loader from '../../components/Loader';
import { shortenAddress } from '../../utils';
import CopyHelper from '../../components/AccountDetails/Copy';
import Row from '../../components/Row';
import { AutoColumn } from '../../components/Column';
import WithdrawButton from './components/WithdrawButton';

function PoolsConsole() {
  const miniPool = useMiniPool(MINI_POOL_ADDRESS);

  const [addOpen, setAddOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pools, setPools] = useState([]);

  const fetchPools = useCallback(async () => {
    setLoading(true);
    try {
      const pools = await miniPool.methods.getAllPools().call();
      console.log(pools);
      setPools(pools);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [miniPool]);

  useEffect(() => {
    if (miniPool) {
      fetchPools();
    }

    return () => {};
  }, [
    miniPool,
    fetchPools,
  ]);

  return (
    <AppBody>
      <AppHeader leftContent={ <Link to={ '/console' }><ArrowLeft /></Link> }
                 rightContent={ <ButtonEmpty padding={ '0' }
                                             onClick={ () => setAddOpen(true) }>
                   <Plus />
                 </ButtonEmpty> }
      >
        <Trans>Pools</Trans>配置
      </AppHeader>

      <AppWrapper>
        <AutoColumn gap={ '12px' }>
          <Row>
            合约地址 { shortenAddress(MINI_POOL_ADDRESS) } <CopyHelper
            toCopy={ MINI_POOL_ADDRESS } />
          </Row>

          { loading ? <Loader /> : <PoolItems miniPool={miniPool} pools={ pools } /> }

          <WithdrawButton miniPool={miniPool}/>
        </AutoColumn>
      </AppWrapper>

      <PoolsAddModal miniPool={ miniPool } isOpen={ addOpen }
                     setIsOpen={ setAddOpen } />
    </AppBody>
  );
}

export default PoolsConsole;
