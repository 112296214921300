import AppBody from '../AppBody';
import AppHeader, { AppWrapper } from '../../components/AppView';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';
import Row, { AutoRow, RowFlat } from '../../components/Row';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWallet } from 'use-wallet';
import CopyHelper from '../../components/AccountDetails/Copy';
import { useMiniSwap } from '../../hooks/useContract';
import { MINI_POOL_ADDRESS, MINI_SWAP_ADDRESS } from '../../constants/address';
import useTokens from '../../hooks/useTokens';
import SwapClaimItems from './components/SwapClaimItems';
import Loader from '../../components/Loader';
import PoolClaimItems from './components/PoolClaimItems';
import { shortenAddress } from '../../utils';
import {
  ButtonSecondary,
} from '../../components/Button';
import copy from 'copy-to-clipboard';
import useUserState from '../../hooks/useUserState';

const PromoteLinkWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const LinkInput = styled.input`
  border: none;
`;

function Claim() {
  const { account } = useWallet();
  const miniSwap = useMiniSwap(MINI_SWAP_ADDRESS);
  const miniPool = useMiniSwap(MINI_POOL_ADDRESS);

  const [loading, setLoading] = useState(false);
  const {
    referrer,
    counts,
  } = useUserState();

  const [outAddresses, setOutAddresses] = useState([]);
  const [outTokens] = useTokens(outAddresses);

  const link = useMemo(() => {
    return `${ window.location.protocol }//${ window.location.host }/#/?ref=${ account }`;
  }, [account]);

  const fetchTokens = useCallback(async () => {
    setLoading(true);
    try {
      const data = await miniSwap.methods.getSwapTokens().call();
      setOutAddresses(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [miniSwap]);

  const handleCopy = useCallback(() => {
    copy(link);
    alert(t`Copied`);
  }, [link]);

  useEffect(() => {
    if (miniSwap) {
      fetchTokens();
    }
    return () => {};
  }, [
    miniSwap,
    fetchTokens,
  ]);

  return (
    <AppBody>
      <AppHeader>
        <Trans>Claim</Trans>
      </AppHeader>

      <AppWrapper>
        { referrer ? (
          <PromoteLinkWrapper>
            <AutoRow gap={ '5px' } justify={ 'space-between' }>
              <div><Trans>Your Superior Address</Trans></div>
              <RowFlat>
                { shortenAddress(referrer) }
                <CopyHelper toCopy={ referrer } />
              </RowFlat>
            </AutoRow>
          </PromoteLinkWrapper>
        ) : null }

        { account ? (
          <>
            <PromoteLinkWrapper>
              <AutoRow gap={ '5px' } justify={ 'space-between' }>
                <div><Trans>Promote link</Trans></div>
                <RowFlat>
                  <LinkInput disabled value={ link } onChange={ () => {} } />
                </RowFlat>
              </AutoRow>
            </PromoteLinkWrapper>

            <PromoteLinkWrapper>
              <AutoRow gap={ '5px' } justify={ 'space-between' }>
                <div><Trans>Number of invitees</Trans></div>
                <RowFlat>
                  { counts }
                </RowFlat>
              </AutoRow>
            </PromoteLinkWrapper>

            <ButtonSecondary onClick={ handleCopy }>
              <Trans>Copy Promotion Link</Trans>
            </ButtonSecondary>
          </>
        ) : null }

        { loading ? (
          <Row justify={ 'center' }><Loader /></Row>
        ) : (
          <>
            <SwapClaimItems tokens={ outTokens }
                            miniSwap={ miniSwap } />
            <PoolClaimItems tokens={ outTokens } miniPool={ miniPool } />
          </>
        ) }
      </AppWrapper>
    </AppBody>
  );
}

export default Claim;
