import { AutoColumn } from '../../components/Column';
import styled from 'styled-components';
import { RowBetween } from '../../components/Row';
import { ThemedText } from '../../theme';
import { Trans } from '@lingui/macro';
import NoDataContent from '../../components/NoDataContent';
import { Coffee } from 'react-feather';
import { useMiniPool } from '../../hooks/useContract';
import { MINI_POOL_ADDRESS } from '../../constants/address';
import { useEffect, useMemo, useState } from 'react';
import PoolItem from './components/PoolItem';
import { useWallet } from 'use-wallet';
import Loader from '../../components/Loader';
import Big from 'big.js';

const Wrapper = styled(AutoColumn)`
  max-width: 820px;
  width: 100%;
`;

const ThemedTextWrapper = styled(ThemedText.Black)`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 400;
  font-size: 20px;
`;

const PoolsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  padding-bottom: 100px;

  ${ ({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
  ` };
`;

function Pools() {
  const { account, getBlockNumber } = useWallet();
  const blockNumber = getBlockNumber();
  const miniPool = useMiniPool(MINI_POOL_ADDRESS);

  const [loading, setLoading] = useState(true);
  const [pools, setPools] = useState([]);

  const filPools = useMemo(() => {
    return pools.filter((pool) => {
      return true // pool.supply !== '0'  pool.supply !== '0' || pool.endRewardBlock >= blockNumber
    }).sort((a, b) => {
      return Number(a.id) > Number(b.id) ? -1: 1
    })
  }, [pools, blockNumber])

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        console.log('pools');
        const pools = await miniPool.methods.getAllPools().call();
        console.log('pools2',pools);
        setPools(pools);
      } catch (e) {
        console.log('pools error:' , e);
      } finally {
        setLoading(false);
      }
    };

    if (miniPool) {
      fetch();
    }
    return () => {};
  }, [
    miniPool,
    blockNumber,
  ]);

  return (
    <Wrapper>
      <AutoColumn gap={ '24px' } style={ { width: '100%' } }>
        <RowBetween>
          <ThemedTextWrapper>
            <Trans>Pools</Trans>
          </ThemedTextWrapper>
        </RowBetween>

        { account && filPools.length ? (
          <PoolsWrapper>
            { filPools.map(pool => <PoolItem key={ pool.id } pool={ pool }
                                          miniPool={ miniPool } />) }
          </PoolsWrapper>
        ) : (
          <NoDataContent icon={ <Coffee size={ 48 } /> }
                         text={ <>
                           <div>
                             <Trans>All active pool positions will appear
                               here.</Trans>
                           </div>
                           <div>
                           { loading ? <Loader /> : null }
                           </div>
                         </> } />
        ) }
      </AutoColumn>
    </Wrapper>
  );
}

export default Pools;
