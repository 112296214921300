import React, { createContext, useCallback, useState } from 'react';
import { UseWalletProvider } from 'use-wallet';
import { DefaultChainId } from '../../constants/chains';

export const Context = createContext({});

function Web3Provider({ children }) {
  const [chainId, setChainId] = useState(DefaultChainId);

  const handleChangeChain = useCallback(async ({
    chainId,
    chainName,
    nativeCurrency,
    rpcUrls,
    explorerUrl,
  }) => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      });
    } catch (e) {
      if (e.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId,
                chainName,
                nativeCurrency,
                rpcUrls,
                blockExplorerUrls: [explorerUrl],
              },
            ],
          });
        } catch (addError) {
          console.log(addError);
        }
      }
    }
  }, []);

  return (
    <Context.Provider value={ {
      switchChain: handleChangeChain,
      chainId,
      setChainId,
    } }>
      <UseWalletProvider
        chainId={ chainId }
        connectors={ {
          walletconnect: {
            rpc: {
              1: 'https://mainnet.infura.io/v3/a0d8c94ba9a946daa5ee149e52fa5ff1',
              4: 'https://rinkeby.infura.io/v3/a0d8c94ba9a946daa5ee149e52fa5ff1',
              56: 'https://bsc-dataseed3.binance.org',
            },
            bridge: 'https://bridge.walletconnect.org',
            pollingInterval: 3000,
          },
        } }
        autoConnect={ true }
        pollBlockNumberInterval={ 3000 }
        pollBalanceInterval={ 3000 }
      >
        { children }
      </UseWalletProvider>
    </Context.Provider>
  );
}

export default Web3Provider;
