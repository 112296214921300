import { createContext, useEffect, useMemo } from 'react';
import { useWallet } from 'use-wallet';
import Web3 from 'web3';
import useWeb3Provider from '../../hooks/useWeb3Provider';
import { ethers } from 'ethers';

export const Context = createContext({});

function Web3Context({ children }) {
  const {
    ethereum,
    chainId,
  } = useWallet();

  const {
    switchChain,
    chainId: targetChain,
  } = useWeb3Provider();

  const web3 = useMemo(() => {
    if (ethereum && chainId) {
      return new Web3(ethereum);
    }
    return null;
  }, [
    ethereum,
    chainId,
  ]);

  useEffect(() => {
    if (targetChain && chainId && targetChain !== chainId) {
      setTimeout(() => {
        switchChain({ chainId: ethers.utils.hexlify(targetChain) });
      }, 1500)
    }
  }, [targetChain, chainId, switchChain]);

  return (
    <Context.Provider value={ { web3 } }>
      { children }
    </Context.Provider>
  );
}

export default Web3Context;
