import { useCallback, useState } from 'react';
import { ethers } from 'ethers';
import { useWallet } from 'use-wallet';

function useERC20Approve(contract, spender, amount = ethers.constants.MaxUint256) {
  const [loading, setLoading] = useState(false);
  const { account } = useWallet();

  const onApprove = useCallback(async (_amount) => {
    try {
      setLoading(true);
      return await contract.methods.approve(spender, _amount || amount).send({ from: account });
    } catch (e) {
      return false;
    } finally {
      setLoading(false);
    }
  }, [
    account,
    amount,
    contract,
    spender,
  ]);

/*   const onApprove2 = useCallback(async (_amount) => {
    try {
      setLoading(true);
      console.log('onApprove2',_amount);
      return await contract.methods.approve(spender, _amount).send({ from: account });
    } catch (e) {
      console.warn('catch',e);
      return false;
    } finally {
      setLoading(false);
    }
  }, [
    account,
    contract,
    spender,
  ]); */

  return [
    // onApprove2,
    onApprove,
    loading,
  ];
}

export default useERC20Approve;
