import Modal from '../../../components/Modal';
import ModalWrapper from '../../../components/ModalWrapper';
import { Trans } from '@lingui/macro';
import { AutoColumn } from '../../../components/Column';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import NumericalInput from '../../../components/NumericalInput';
import Row from '../../../components/Row';
import useERC20Allowance from '../../../hooks/useERC20Allowance';
import {
  MINI_POOL_ADDRESS,
} from '../../../constants/address';
import useERC20Approve from '../../../hooks/useERC20Approve';
import { useWallet } from 'use-wallet';
import { ButtonLight, ButtonPrimary } from '../../../components/Button';
import Loader from '../../../components/Loader';
import useERC20Balance from '../../../hooks/useERC20Balance';
import { fromAmount, toAmount } from '../../../utils/amount';
import Big from 'big.js';

const InputText = styled(NumericalInput)`
  width: 100%;
  border-radius: 24px;
  padding: 8px 12px;
`;

const LabelWrapper = styled.div`
  font-size: 14px;
`;

const Symbol = styled.div`
  margin-left: 8px;
`;

const BalanceWrapper = styled.div`
  font-size: 14px;
`;

function PoolDepositModal({
  isOpen,
  setIsOpen,
  depositToken,
  userInfo,
  pool,
  miniPool,
}) {

  const { account } = useWallet();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const tokenContract = useMemo(() => {
    if (depositToken) {
      return depositToken.contract;
    }
    return null;
  }, [depositToken]);

  const isLimit = useMemo(() => {
    if (userInfo && value && depositToken && pool && pool.limit !== '0') {
      const _amount = new Big(fromAmount(value, depositToken.decimals)).
        add(userInfo.amount);
      return _amount.gt(pool.limit);
    }
    return false;
  }, [
    userInfo,
    value,
    pool,
    depositToken,
  ]);

  const balance = useERC20Balance(tokenContract, account);

  const [allowance, fetchAllowance] = useERC20Allowance(
    tokenContract, account, MINI_POOL_ADDRESS);

  const [onApprove, approveLoading] = useERC20Approve(
    tokenContract, MINI_POOL_ADDRESS);

  const handleOnApprove = useCallback(async () => {
    await onApprove(fromAmount(value, depositToken?depositToken.decimals:18));
    await fetchAllowance();
  }, [
    fetchAllowance,
    onApprove,
    value,
  ]);

  const handleOnDeposit = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await miniPool.methods.deposit(pool.id,
        fromAmount(value, depositToken.decimals),
      ).
        send({ from: account });

      console.log(tx);

      setIsOpen(false);

    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

  }, [
    miniPool,
    pool,
    value,
    depositToken,
    account,
    setIsOpen,
  ]);

  return (
    <Modal isOpen={ isOpen } onDismiss={ () => setIsOpen(false) }>
      { depositToken ? (
        <ModalWrapper title={ <Trans>Deposit</Trans> }
                      toggleWalletModal={ () => setIsOpen(false) }>
          <AutoColumn gap={ '24px' }>
            <AutoColumn gap={ 'sm' }>
              <LabelWrapper>
                <Trans>Deposit Amount</Trans>
              </LabelWrapper>

              <Row>
                <InputText value={ value }
                           onUserInput={ setValue } />
                <Symbol>{ depositToken.symbol }</Symbol>
              </Row>

              <BalanceWrapper>
                <Trans>Balance: { toAmount(balance, depositToken.decimals).
                  toFixed(6, 0) }</Trans> { depositToken.symbol }
              </BalanceWrapper>
            </AutoColumn>

            { allowance.lt(fromAmount(value || '0', depositToken?depositToken.decimals:18)) ? (
              <ButtonLight
                disabled={ approveLoading }
                onClick={ handleOnApprove }
              >
              { approveLoading ? <Loader /> : <Trans>Approve</Trans> }
              </ButtonLight>
            ) : (
              <ButtonPrimary
                onClick={ handleOnDeposit }
                disabled={ loading || !value || value <= 0 || isLimit }>
                { loading ? <Loader /> : (
                  isLimit ? <Trans>Deposit Limit</Trans> :
                    <Trans>Deposit</Trans>
                ) }
              </ButtonPrimary>
            ) }
          </AutoColumn>
        </ModalWrapper>
      ) : null }
    </Modal>
  );
}

export default PoolDepositModal;
