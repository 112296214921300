// eslint-disable-next-line no-restricted-imports
import { t, Trans } from '@lingui/macro'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  BookOpen, Check, ChevronLeft,
  Coffee,
  Globe,
  HelpCircle,
  Info,
  MessageCircle,
} from 'react-feather'
import styled, { css } from 'styled-components/macro'

import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ExternalLink } from '../../theme'
import useActiveLocale from '../../hooks/useActiveLocale'
import { LOCALES_TEXT, SUPPORTED_LOCALES } from '../../constants/locales'

export const FlyoutAlignment = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
}

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  margin: 0;
  height: 40px;
  background-color: ${({ theme }) => theme.bg0};
  border: 1px solid ${({ theme }) => theme.bg0};
  padding: 0.15rem 0.5rem;
  border-radius: 16px;

  &:hover, &:focus {
    cursor: pointer;
    outline: none;
    border: 1px solid ${({ theme }) => theme.bg3};
  }

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 196px;
  max-height: 350px;
  overflow: auto;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  0px 24px 32px rgba(0, 0, 0, 0.01);
  border: 1px solid ${({ theme }) => theme.bg0};
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  position: absolute;
  top: 3rem;
  z-index: 100;

  ${({ flyoutAlignment = FlyoutAlignment.RIGHT }) =>
          flyoutAlignment === FlyoutAlignment.RIGHT
                  ? css`
                    right: 0rem;
                  `
                  : css`
                    left: 0rem;
                  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    bottom: unset;
    right: 0;
    left: unset;
  `};
`

const MenuItem = styled(ExternalLink)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  color: ${({ theme }) => theme.text2};

  &:hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const InternalLinkMenuItem = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};

  &:hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }

  > svg {
    margin-right: 8px;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

const ToggleMenuItem = styled.button`
  background-color: transparent;
  margin: 0;
  border: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0.5rem;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text2};

  &:hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
`

function LanguageMenuItem ({ locale, active }) {
  const { setLocale } = useActiveLocale()

  const onClick = useCallback(() => {
    setLocale(locale)
  }, [locale, setLocale])

  return (
    <InternalLinkMenuItem onClick={onClick} to={''}>
      <div>{LOCALES_TEXT[locale]}</div>
      {active && <Check opacity={0.6} size={16} />}
    </InternalLinkMenuItem>
  )
}

function LanguageMenu ({ close }) {
  const { locale: activeLocale } = useActiveLocale()

  return (
    <MenuFlyout>
      <ToggleMenuItem onClick={close}>
        <ChevronLeft size={16} />
      </ToggleMenuItem>
      {SUPPORTED_LOCALES.map((locale) => (
        <LanguageMenuItem locale={locale} active={activeLocale === locale} key={locale} />
      ))}
    </MenuFlyout>
  )
}

export default function Menu () {
  const node = useRef()
  const [open, setOpen] = useState(false)

  const toggleMenu = useCallback(() => {
    setOpen(!open)
  }, [open])

  useOnClickOutside(node, open ? toggleMenu : undefined)

  const [menu, setMenu] = useState('main')

  useEffect(() => {
    setMenu('main')
  }, [open])

  return (
    <>
      <StyledMenu ref={node}>
        <StyledMenuButton onClick={toggleMenu} aria-label={t`Menu`}>
          <StyledMenuIcon />
        </StyledMenuButton>

        {open &&
          (() => {
            switch (menu) {
              case 'lang':
                return <LanguageMenu close={() => setMenu('main')} />
              case 'main':
              default:
                return (
                  <MenuFlyout>
                    <MenuItem href="/">
                      <div>
                        <Trans>About</Trans>
                      </div>
                      <Info opacity={0.6} size={16} />
                    </MenuItem>
                    <MenuItem href="/">
                      <div>
                        <Trans>Help Center</Trans>
                      </div>
                      <HelpCircle opacity={0.6} size={16} />
                    </MenuItem>
                    <MenuItem href="/">
                      <div>
                        <Trans>Request Features</Trans>
                      </div>
                      <Coffee opacity={0.6} size={16} />
                    </MenuItem>
                    <MenuItem href="/">
                      <div>
                        <Trans>Discord</Trans>
                      </div>
                      <MessageCircle opacity={0.6} size={16} />
                    </MenuItem>
                    <ToggleMenuItem onClick={() => setMenu('lang')}>
                      <div>
                        <Trans>Language</Trans>
                      </div>
                      <Globe opacity={0.6} size={16} />
                    </ToggleMenuItem>

                    <MenuItem href="/">
                      <div>
                        <Trans>Docs</Trans>
                      </div>
                      <BookOpen opacity={0.6} size={16} />
                    </MenuItem>
                  </MenuFlyout>
                )
            }
          })()}
      </StyledMenu>
    </>
  )
}
