import { useContext } from 'react';
import { Context } from '../contexts/Web3Provider';

function useWeb3Provider() {
  const {
    switchChain,
    chainId,
  } = useContext(Context);
  return {
    switchChain,
    chainId,
  };
}

export default useWeb3Provider;
