import { Trans } from '@lingui/macro';
import useScrollPosition from '@react-hook/window-scroll';
import { darken } from 'polished';
import { NavLink } from 'react-router-dom';
import { Text } from 'rebass';
import styled from 'styled-components/macro';

import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { ExternalLink } from '../../theme';
import Menu from '../Menu';
import Row from '../Row';
import useTheme from '../../hooks/useTheme';
import Web3Status from '../Web3Status';
import { useWallet } from 'use-wallet';
import { toSpecialAmount } from '../../utils/amount';
import { useMiniSwap } from '../../hooks/useContract';
import { MINI_SWAP_ADDRESS } from '../../constants/address';
import { useEffect, useState } from 'react';
import NetworkSelector from './NetworkSelector';

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 21;
  /* Background slide effect on scroll. */
  background-image: ${ ({ theme }) => `linear-gradient(to bottom, transparent 50%, ${ theme.bg0 } 50% )}}` };
  background-position: ${ ({ showBackground }) => (
          showBackground ? '0 -100%' : '0 0'
  ) };
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px ${ ({
    theme,
    showBackground,
  }) => (
          showBackground ? theme.bg2 : 'transparent;'
  ) };
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;

  ${ ({ theme }) => theme.mediaWidth.upToLarge`
    grid-template-columns: 48px 1fr 1fr;
  ` };

  ${ ({ theme }) => theme.mediaWidth.upToMedium`
    padding:  1rem;
    grid-template-columns: 1fr 1fr;
  ` };

  ${ ({ theme }) => theme.mediaWidth.upToSmall`
    padding:  1rem;
    grid-template-columns: 36px 1fr;
  ` };
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`;

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */

  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${ ({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  ` };
`;

const HeaderLinks = styled(Row)`
  justify-self: center;
  background-color: ${ ({ theme }) => theme.bg0 };
  width: fit-content;
  padding: 2px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  ${ ({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;  
    ` };
  ${ ({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  ` };
  ${ ({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${ ({ theme }) => theme.bg0 };
    border: 1px solid ${ ({ theme }) => theme.bg2 };
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  ` };
`;

const AccountElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${ ({
    theme,
    active,
  }) => (
          !active ? theme.bg0 : theme.bg0
  ) };
  border-radius: 16px;
  white-space: nowrap;
  width: 100%;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`;

const BalanceText = styled(Text)`
  ${ ({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  ` };
`;

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${ ({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  ` };

  :hover {
    cursor: pointer;
  }
`;

const UniIcon = styled.div`
  transition: transform 0.3s ease;

  :hover {
    transform: rotate(30deg);
  }

  position: relative;
`;

const StyledNavLink = styled(NavLink)`
  ${ ({ theme }) => theme.flexRowNoWrap }
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${ ({ theme }) => theme.text2 };
  font-size: 1rem;
  font-weight: 500;
  padding: 8px 12px;
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;

  &.active {
    border-radius: 14px;
    font-weight: 600;
    justify-content: center;
    color: ${ ({ theme }) => theme.text1 };
    background-color: ${ ({ theme }) => theme.bg1 };
  }

  &:hover, &:focus {
    color: ${ ({ theme }) => darken(0.1, theme.text1) };
  }

  ${ ({ disabled }) => disabled && `
    opacity: 50%;
    cursor: auto;
    pointer-events: none;
  ` }
`;

// eslint-disable-next-line no-unused-vars
const StyledExternalLink = styled(ExternalLink)`
  ${ ({ theme }) => theme.flexRowNoWrap }
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${ ({ theme }) => theme.text2 };
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.active {
    border-radius: 14px;
    font-weight: 600;
    color: ${ ({ theme }) => theme.text1 };
  }

  &:hover, &:focus {
    color: ${ ({ theme }) => darken(0.1, theme.text1) };
    text-decoration: none;
  }
`;

export default function Header() {
  const {
    white,
    black,
  } = useTheme();
  const scrollY = useScrollPosition();

  const {
    account,
    balance: userEthBalance,
  } = useWallet();
  const nativeCurrencySymbol = 'BNB';

  const swap = useMiniSwap(MINI_SWAP_ADDRESS);

  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const owner = await swap.methods.owner().call();
        setIsOwner(account === owner);
      } catch (e) {
        console.log(e);
      }
    };

    if (swap && account) {
      fetch();
    }
    return () => {};
  }, [
    swap,
    account,
  ]);

  const darkMode = false;

  return (
    <HeaderFrame showBackground={ scrollY > 45 }>
      <Title href=".">
        <UniIcon>
          <Logo fill={ darkMode ? white : black } width="32px" height="100%"
                title="logo" />
        </UniIcon>
      </Title>
      <HeaderLinks>
        <StyledNavLink to={ '/swap' }>
          <Trans>Swap</Trans>
        </StyledNavLink>
        <StyledNavLink to={ '/pools' }>
          <Trans>Pools</Trans>
        </StyledNavLink>
        <StyledNavLink to={ '/claim' }>
          <Trans>Claim</Trans>
        </StyledNavLink>
        { isOwner ? (
          <StyledNavLink to={ '/console' }>
            <Trans>Console</Trans>
          </StyledNavLink>
        ) : null }

{/*         <StyledExternalLink href={ 'https://v1.mswap.pro/' }>
          <Trans>V1</Trans>
          <sup>↗</sup>
        </StyledExternalLink> */}
      </HeaderLinks>

      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          <AccountElement active={ !!account }>
            { account && userEthBalance ? (
              <BalanceText style={ {
                flexShrink: 0,
                userSelect: 'none',
              } } pl="0.75rem" pr="0.5rem" fontWeight={ 500 }>
                { toSpecialAmount(userEthBalance) } { nativeCurrencySymbol }
              </BalanceText>
            ) : null }
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HeaderElement>
          <Menu />
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  );
}
