import Web3 from 'web3';

import erc20Abi from '../abis/ERC20.json';
import miniSwapAbi from '../abis/MiniSwap.json';
import miniPoolAbi from '../abis/MiniPool.json';
import miniRegisterAbi from '../abis/MiniRegister.json';

// 零地址
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? new Web3();
  return new _web3.eth.Contract(abi, address);
};

export const getERC20Contract = (address, web3) => {
  return getContract(erc20Abi, address, web3);
};

export const getMiniSwapContract = (address, web3) => {
  return getContract(miniSwapAbi, address, web3);
};

export const getMiniPoolContract = (address, web3) => {
  return getContract(miniPoolAbi, address, web3);
};

export const getMiniRegisterContract = (address, web3) => {
  return getContract(miniRegisterAbi, address, web3);
};
