import { useEffect, useState } from 'react';
import useWeb3 from './useWeb3';
import { getERC20Contract, ZERO_ADDRESS } from '../utils/ethereum';
import { Token } from '../utils/types';
import { ETH_TOKEN_ADDRESS, USDT_ADDRESS } from '../constants/address';

const LocalTokens = {};

function useTokens(tokenAddress = []) {
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState([]);

  const web3 = useWeb3();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const tokens = await Promise.all(tokenAddress.map(async (address) => {
          if (LocalTokens[address]) {
            return LocalTokens[address];
          }

          if (address === ZERO_ADDRESS) {
            return new Token({
              address,
              name: 'Binance Wrapped BNB',
              symbol: 'BNB',
              decimals: 18,
              icon: require('../assets/images/bnb.png'),
            });
          }

          try {
            const erc20 = getERC20Contract(address, web3);
            const token = new Token({
              address,
              name: await erc20.methods.name().call(),
              symbol: await erc20.methods.symbol().call(),
              decimals: await erc20.methods.decimals().call(),
              icon: `/tokens/${ address }.png`,
              contract: erc20,
            });

            if (token.address === USDT_ADDRESS) {
              token.name = 'Binance-Peg USDT Token';
            } else if (token.address===ETH_TOKEN_ADDRESS) {
              token.name = 'Binance-Peg Ethereum Token';
            }

            return token
          } catch (e) {
            console.log(e);
            return null;
          }
        }));

        tokens.forEach(e => {
          LocalTokens[e.address] = e;
        });

        setTokens(tokens.filter((e) => e != null));
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    if (web3) {
      fetch();
    }
    return () => {};
  }, [
    web3,
    tokenAddress,
  ]);

  return [
    tokens,
    loading,
  ];
}

export default useTokens;
