// Swap地址
import { ZERO_ADDRESS } from '../utils/ethereum';

export const ETH_ADDRESS = ZERO_ADDRESS;

// product
export const USDT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955';
export const ETH_TOKEN_ADDRESS = '0x2170Ed0880ac9A755fd29B2688956BD959F933F8';

// test
// export const USDT_ADDRESS = '0xfffff1adb4d8ABA145ABbAb8d3EdcAc7aE451CA6';
// export const ETH_TOKEN_ADDRESS = '0x2170Ed0880ac9A755fd29B2688956BD959F933F8';

// v1
// export const  MINI_SWAP_ADDRESS = '0xfffff1adb4d8ABA145ABbAb8d3EdcAc7aE451CA6';
// v2
// export const MINI_SWAP_ADDRESS = '0x680CB3f6FBAFe4e0344204D4Fc18E1E3BF77DAD6';
// v3 test
// export const MINI_SWAP_ADDRESS = '0xBEd0EE9746443d446EC61BDA89a6bB0e99340555';
// v3
// export const MINI_SWAP_ADDRESS = '0x4c13Ee9EB6B7623b8dA9F77BB049367b1C386641';
// v4
export const MINI_SWAP_ADDRESS = '0x39ce9db0b30320807f592885614c3f3df786b6a6';

// v1
// export const MINI_POOL_ADDRESS = '0xa01a715DE0109814D5914034c6Bf6e7eAe046408';
// v2
// export const MINI_POOL_ADDRESS = '0x6ec1248FEa489ffB766801ba55B7a39Ec9F45A94';
// v3 test
// export const MINI_POOL_ADDRESS = '0x6E9db586c392F12340ffCc6C87b6f414B8930efC';
// v3
// export const MINI_POOL_ADDRESS = '0x1A4bDe2bD4E74227781BB7cc190197c9192D48e3';
// mytest                         testtoken 0x503e9e6Eb8f9fCbbbaa7d0534fe8E1EF11D42fdb
// export const MINI_POOL_ADDRESS = '0x890878a02e42279914236FeA9c3BB7eA2f4C0aB1';
// export const MINI_POOL_ADDRESS = '0x6423aB00AE19F7BF42817e3195D46051C34E5D49';

// v4
export const MINI_POOL_ADDRESS = '0xfed27b9c96fd6d8a9fef31652aedc46d1bed5626';


// v3 test
// export const MINI_REGISTER_ADDRESS = '0x2A5a81176De32Dbcbdff44344eF353ca15587B56'
// v3
//export const MINI_REGISTER_ADDRESS='0x08b65e47f90F4e7650C4d45513CBE4e458Bbb064'
// mytest 
//export const MINI_REGISTER_ADDRESS='0x02663Db2FE3B0d67e712eC1Af8Eb376A30D34970'
//V4
export const MINI_REGISTER_ADDRESS='0x5a7f8505a9a28ae680ce045155a55a286de24e6c'
/**
 *  geth 链 4
 * User ERC20: 0x503e9e6Eb8f9fCbbbaa7d0534fe8E1EF11D42fdb;
 * reg user ：0x162b27079FB5BdAD0C9840852610f39BA9524418
   mswap loop：0x8d808ad1c472D264D402888edaA4E72344607e0D
 */
