import { useContext } from 'react';
import { Context } from '../contexts/UserState';

function useUserState() {
  const {
    user,
    counts,
    referrer,
    fetchUser,
    onRegister,
  } = useContext(Context);

  return {
    user,
    counts,
    referrer,
    fetchUser,
    onRegister,
  };
}

export default useUserState;
