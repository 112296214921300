import { Trans } from '@lingui/macro';
import { ExternalLink as LinkIcon } from 'react-feather';
import styled from 'styled-components/macro';

import { ReactComponent as Close } from '../../assets/images/x.svg';
import { SUPPORTED_WALLETS } from '../../constants/wallet';
import { ExternalLink } from '../../theme';
import { shortenAddress } from '../../utils';
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink';
import { ButtonSecondary } from '../Button';
import Copy from './Copy';
import { useWallet } from 'use-wallet';

const HeaderRow = styled.div`
  ${ ({ theme }) => theme.flexRowNoWrap };
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${ (props) => (
          props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit'
  ) };
  ${ ({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  ` };
`;

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid ${ ({ theme }) => theme.bg3 };
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`;

const AccountGroupingRow = styled.div`
  ${ ({ theme }) => theme.flexRowNoWrap };
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${ ({ theme }) => theme.text1 };

  div {
    ${ ({ theme }) => theme.flexRowNoWrap }
    align-items: center;
  }
`;

const AccountSection = styled.div`
  padding: 0rem 1rem;
  ${ ({ theme }) => theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;` };
`;

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`;

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const AddressLink = styled(ExternalLink)`
  font-size: 0.825rem;
  color: ${ ({ theme }) => theme.text3 };
  margin-left: 1rem;
  font-size: 0.825rem;
  display: flex;

  :hover {
    color: ${ ({ theme }) => theme.text2 };
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const CloseColor = styled(Close)`
  path {
    stroke: ${ ({ theme }) => theme.text4 };
  }
`;

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${ ({ theme }) => theme.text3 };
`;

const IconWrapper = styled.div`
  ${ ({ theme }) => theme.flexColumnNoWrap };
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  & > img,
  span {
    height: ${ ({ size }) => (
            size ? size + 'px' : '32px'
    ) };
    width: ${ ({ size }) => (
            size ? size + 'px' : '32px'
    ) };
  }

  ${ ({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  ` };
`;

function WrappedStatusIcon({ connector }) {
  return (
    <IconWrapper size={ 16 }>
      {/*<StatusIcon connector={connector} />*/ }
      { connector === 'portis' && (
        <MainWalletAction
          onClick={ () => {
            // todo
          } }
        >
          <Trans>Show Portis</Trans>
        </MainWalletAction>
      ) }
    </IconWrapper>
  );
}

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const MainWalletAction = styled(WalletAction)`
  color: ${ ({ theme }) => theme.primary1 };
`;

export default function AccountDetails({
  toggleWalletModal,
  ENSName,
  openOptions,
}) {
  const {
    chainId,
    account,
    connector,
  } = useWallet();

  function formatConnectorName() {
    const {
      ethereum,
    } = window;
    const isMetaMask = !!(
      ethereum && ethereum.isMetaMask
    );
    let name = Object.keys(SUPPORTED_WALLETS).
      filter((k) => SUPPORTED_WALLETS[k].connector === connector && (
        connector !== 'injected' || isMetaMask === (
          k === 'METAMASK'
        )
      )).
      map((k) => SUPPORTED_WALLETS[k].name)[0];
    if (!!(
      window.TPJSBrigeClient || (
        window.webkit && window.webkit.messageHandlers &&
        window.webkit.messageHandlers.getDeviceId
      )
    )) {
      name = 'TokenPocket';
    }
    return (
      <WalletName>
        <Trans>Connected with { name }</Trans>
      </WalletName>
    );
  }

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={ toggleWalletModal }>
          <CloseColor />
        </CloseIcon>
        <HeaderRow>
          <Trans>Account</Trans>
        </HeaderRow>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow>
                { formatConnectorName() }
                <div>
                  { connector !== 'injected' && connector !== 'walletlink' && (
                    <WalletAction
                      style={ {
                        fontSize: '.825rem',
                        fontWeight: 400,
                        marginRight: '8px',
                      } }
                      onClick={ () => {
                        // todo
                      } }
                    >
                      <Trans>Disconnect</Trans>
                    </WalletAction>
                  ) }
                  <WalletAction
                    style={ {
                      fontSize: '.825rem',
                      fontWeight: 400,
                    } }
                    onClick={ () => {
                      openOptions();
                    } }
                  >
                    <Trans>Change</Trans>
                  </WalletAction>
                </div>
              </AccountGroupingRow>
              <AccountGroupingRow id="web3-account-identifier-row">
                <AccountControl>
                  { ENSName ? (
                    <>
                      <div>
                        { connector &&
                          <WrappedStatusIcon connector={ connector } /> }
                        <p> { ENSName }</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        { connector &&
                          <WrappedStatusIcon connector={ connector } /> }
                        <p> { account && shortenAddress(account) }</p>
                      </div>
                    </>
                  ) }
                </AccountControl>
              </AccountGroupingRow>
              <AccountGroupingRow>
                { ENSName ? (
                  <>
                    <AccountControl>
                      <div>
                        { account && (
                          <Copy toCopy={ account }>
                            <span style={ { marginLeft: '4px' } }>
                              <Trans>Copy Address</Trans>
                            </span>
                          </Copy>
                        ) }
                        { chainId && account && (
                          <AddressLink
                            hasENS={ !!ENSName }
                            isENS={ true }
                            href={ getExplorerLink(
                              chainId, ENSName, ExplorerDataType.ADDRESS) }
                          >
                            <LinkIcon size={ 16 } />
                            <span style={ { marginLeft: '4px' } }>
                              <Trans>View on Explorer</Trans>
                            </span>
                          </AddressLink>
                        ) }
                      </div>
                    </AccountControl>
                  </>
                ) : (
                  <>
                    <AccountControl>
                      <div>
                        { account && (
                          <Copy toCopy={ account }>
                            <span style={ { marginLeft: '4px' } }>
                              <Trans>Copy Address</Trans>
                            </span>
                          </Copy>
                        ) }
                        { chainId && account && (
                          <AddressLink
                            hasENS={ !!ENSName }
                            isENS={ false }
                            href={ getExplorerLink(
                              chainId, account, ExplorerDataType.ADDRESS) }
                          >
                            <LinkIcon size={ 16 } />
                            <span style={ { marginLeft: '4px' } }>
                              <Trans>View on Explorer</Trans>
                            </span>
                          </AddressLink>
                        ) }
                      </div>
                    </AccountControl>
                  </>
                ) }
              </AccountGroupingRow>
            </InfoCard>
          </YourAccount>
        </AccountSection>
      </UpperSection>
    </>
  );
}
