import useWeb3 from './useWeb3';
import { useCallback, useEffect, useState } from 'react';
import Big from 'big.js';
import { useWallet } from 'use-wallet';

function useERC20Allowance(contract, owner, spender) {
  const web3 = useWeb3();
  const [value, setValue] = useState(new Big(0));
  const { getBlockNumber } = useWallet();
  const blockNumber = getBlockNumber();

  const fetch = useCallback(async () => {
    try {
      setValue(
        new Big(await contract.methods.allowance(owner, spender).call()));
    } catch (e) {
      console.log(e);
    }
  }, [
    contract,
    setValue,
    owner,
    spender,
  ]);

  useEffect(() => {
    if (web3 && contract) {
      fetch();
    }
    return () => {};
  }, [
    web3,
    contract,
    setValue,
    owner,
    spender,
    blockNumber,
    fetch,
  ]);

  return [
    value,
    fetch,
  ];
}

export default useERC20Allowance;
