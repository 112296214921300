import Modal from '../../../components/Modal';
import ModalWrapper from '../../../components/ModalWrapper';
import { useWallet } from 'use-wallet';
import { useMiniSwap } from '../../../hooks/useContract';
import {
  MINI_POOL_ADDRESS, MINI_SWAP_ADDRESS,
} from '../../../constants/address';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useTokens from '../../../hooks/useTokens';
import useERC20Allowance from '../../../hooks/useERC20Allowance';
import useERC20Approve from '../../../hooks/useERC20Approve';
import Big from 'big.js';
import { fromAmount } from '../../../utils/amount';
import { AutoColumn } from '../../../components/Column';
import { Trans } from '@lingui/macro';
import CurrencyInputPanel from '../../../components/CurrencyInputPanel';
import { ButtonLight, ButtonPrimary } from '../../../components/Button';
import Loader from '../../../components/Loader';
import styled from 'styled-components';
import NumericalInput from '../../../components/NumericalInput';
import { BLOCK_PER_SEC } from '../../../constants/chains';
import Row from '../../../components/Row';
import moment from 'moment';

const InputText = styled(NumericalInput)`
  width: 100%;
  border-radius: 24px;
  padding: 8px 12px;
`;

const NeedPayAmount = styled.div`
  font-size: 24px;
`;

const LabelWrapper = styled.div`
  font-size: 14px;
`;

function PoolsAddModal({
  isOpen,
  setIsOpen,
  miniPool,
}) {
  const { account } = useWallet();
  const miniSwap = useMiniSwap(MINI_SWAP_ADDRESS);

  const [outAddresses, setOutAddresses] = useState([]);
  const [tokens] = useTokens(outAddresses);

  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState();
  const [pledgeToken, setPledgeToken] = useState();

  const [tokenPerBlock, setTokenPerBlock] = useState('');
  const [duration, setDuration] = useState('');
  const [awardRate, setAwardRate] = useState('');
  const [awardRate2, setAwardRate2] = useState('');
  const [limit, setLimit] = useState('');
  const [endTime, setEndTime] = useState('');

  const [totalBlock, setTotalBlock] = useState();

  const tokenContract = useMemo(() => {
    if (token) {
      return token.contract;
    }
    return null;
  }, [token]);

  const [allowance, fetchAllowance] = useERC20Allowance(
    tokenContract, account, MINI_POOL_ADDRESS);

  const [onApprove, approveLoading] = useERC20Approve(
    tokenContract, MINI_POOL_ADDRESS);

  const totalAmount = useMemo(() => {
    if (totalBlock && tokenPerBlock) {
      return new Big(totalBlock).times(tokenPerBlock);
    }
    return new Big(0);
  }, [
    tokenPerBlock,
    totalBlock,
  ]);

  const handleOnApprove = useCallback(async () => {
    console.log('handleOnApprove');
    await onApprove(fromAmount(totalAmount, token.decimals));
    await fetchAllowance();
  }, [
    token,
    fetchAllowance,
    onApprove,
    totalAmount,
  ]);

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      const endTimeValue = moment().add(endTime, 'hours').unix();

      await miniPool.methods.addPool(pledgeToken.address, token.address,
        totalBlock, fromAmount(tokenPerBlock, token.decimals), awardRate,
        awardRate2, fromAmount(limit || 0, token.decimals),endTimeValue
      ).send({ from: account });

      window.alert('添加成功');

      setIsOpen(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    setIsOpen,
    account,
    miniPool,
    pledgeToken,
    token,
    tokenPerBlock,
    totalBlock,
    awardRate,
    awardRate2,
    limit,
  ]);

  useEffect(() => {
    if (duration) {
      setTotalBlock(new Big(duration).mul(3600).div(BLOCK_PER_SEC).toFixed(0));
    } else {
      setTotalBlock(0);
    }
  }, [
    duration,
  ]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await miniSwap.methods.getSwapTokens().call();
        setOutAddresses(data);
      } catch (e) {
        console.log(e);
      }
    };

    if (miniSwap) {
      fetch();
    }
    return () => {};
  }, [
    miniSwap,
  ]);

  return (
    <Modal isOpen={ isOpen } onDismiss={ () => setIsOpen(false) }>
      <ModalWrapper title={ '池子添加' }
                    toggleWalletModal={ () => setIsOpen(false) }>

        <AutoColumn gap={ '24px' }>
          <AutoColumn gap={ 'sm' }>
            <LabelWrapper>
              <Trans>Pledge Token</Trans>
            </LabelWrapper>
            <CurrencyInputPanel tokens={ tokens }
                                loading={ loading }
                                currency={ pledgeToken }
                                currencyBalance={ '0' }
                                onCurrencyChange={ setPledgeToken }
                                showMaxButton={ false }
                                inputDisabled={ true }
                                hideBalance={ true }
                                hideInput={ true }
            />
          </AutoColumn>

          <AutoColumn gap={ 'sm' }>
            <LabelWrapper>
              <Trans>Issued Token</Trans>
            </LabelWrapper>
            <CurrencyInputPanel tokens={ tokens }
                                loading={ loading }
                                currency={ token }
                                currencyBalance={ '0' }
                                onCurrencyChange={ setToken }
                                showMaxButton={ false }
                                inputDisabled={ true }
                                hideBalance={ true }
                                hideInput={ true }
            />
          </AutoColumn>

          <AutoColumn gap={ 'sm' }>
            <LabelWrapper>
              <Trans>Issued per block</Trans>
            </LabelWrapper>

            <div>
              <InputText value={ tokenPerBlock }
                         onUserInput={ setTokenPerBlock } />
            </div>
          </AutoColumn>

          <AutoColumn gap={ 'sm' }>
            <LabelWrapper>
              <Trans>Issued Duration (Hour)</Trans>
            </LabelWrapper>

            <div>
              <InputText value={ duration } onUserInput={ setDuration } />
            </div>
          </AutoColumn>

          <AutoColumn gap={ 'sm' }>
            <LabelWrapper>
              <Trans>How many hours to redeem (Hour)</Trans>
            </LabelWrapper>

            <div>
              <InputText value={ endTime } onUserInput={ setEndTime } />
            </div>
          </AutoColumn>

          <AutoColumn gap={ 'sm' }>
            <LabelWrapper>
              一级：<Trans>Invitation Reward</Trans>（千分之几）
            </LabelWrapper>

            <Row>
              <InputText value={ awardRate } onUserInput={ setAwardRate } />
            </Row>
          </AutoColumn>

          <AutoColumn gap={ 'sm' }>
            <LabelWrapper>
              二级：<Trans>Friendship Reward</Trans>（千分之几）
            </LabelWrapper>

            <Row>
              <InputText value={ awardRate2 } onUserInput={ setAwardRate2 } />
            </Row>
          </AutoColumn>

          <AutoColumn gap={ 'sm' }>
            <LabelWrapper>
              <Trans>Limit of Address</Trans>
            </LabelWrapper>

            <div>
              <InputText value={ limit }
                         onUserInput={ setLimit } />
            </div>
          </AutoColumn>

          { totalAmount.gt(0) ? (
            <AutoColumn gap={ '10px' } justify={ 'center' }>
              <div>
                <Trans>Need Pay</Trans>
              </div>
              <NeedPayAmount>
                { totalAmount.toString() } { token ? token.symbol : '~' }
              </NeedPayAmount>
            </AutoColumn>
          ) : null }

          { !allowance.gte(fromAmount(totalAmount || 0, token ? token.decimals:18)) ? (
            <ButtonLight
              disabled={ approveLoading || !token }
              onClick={ handleOnApprove }
            >
              <Trans>Approve</Trans>
              { approveLoading ? <Loader /> : null }
            </ButtonLight>
          ) : (
            <ButtonPrimary
              onClick={ handleCreate }
              disabled={ loading || !token || !pledgeToken || !tokenPerBlock ||
                !awardRate || totalAmount.lte(0) }>
              <Trans>Create Pool</Trans>
              { loading ? <Loader /> : null }
            </ButtonPrimary>
          ) }
        </AutoColumn>
      </ModalWrapper>
    </Modal>
  );
}

export default PoolsAddModal;
