import useWeb3 from './useWeb3';
import {
  getERC20Contract,
  getMiniPoolContract,
  getMiniRegisterContract,
  getMiniSwapContract,
} from '../utils/ethereum';
import { useMemo } from 'react';

/**
 * ERC20
 * @param address
 * @returns {Contract}
 */
export const useERC20 = (address) => {
  const web3 = useWeb3();
  return useMemo(() => address && web3 ? getERC20Contract(address, web3) : null,
    [
      address,
      web3,
    ],
  );
};

export const useMiniSwap = (address) => {
  const web3 = useWeb3();
  return useMemo(
    () => address && web3 ? getMiniSwapContract(address, web3) : null, [
      address,
      web3,
    ]);
};

export const useMiniRegister = (address) => {
  const web3 = useWeb3();
  return useMemo(
    () => address && web3 ? getMiniRegisterContract(address, web3) : null, [
      address,
      web3,
    ]);
};

export const useMiniPool = (address) => {
  const web3 = useWeb3();

  return useMemo(
    () => address && web3 ? getMiniPoolContract(address, web3) : null, [
      address,
      web3,
    ]);
};
