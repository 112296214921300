import styled from 'styled-components';
import CurrencyLogo from '../../../components/CurrencyLogo';
import Column, { AutoColumn } from '../../../components/Column';
import { ThemedText } from '../../../theme';
import { fromAmount, toAmount } from '../../../utils/amount';
import { useCallback, useEffect, useState } from 'react';
import Big from 'big.js';
import { ButtonEmpty } from '../../../components/Button';
import Loader from '../../../components/Loader';
import { useWallet } from 'use-wallet';
import useERC20Balance from '../../../hooks/useERC20Balance';
import { MINI_SWAP_ADDRESS } from '../../../constants/address';

const ListItemName = styled(ThemedText.DarkGray)`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 300;
  font-size: 12px;
`;

const TokenItemWrapper = styled.div`
  padding: 4px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0px, 72px);
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: rgb(237, 238, 242);
  }
`;

const ValueText = styled.span`
  font-size: 12px;
`;

const ListItemValue = styled.div`
  justify-self: flex-end;
  font-size: 12px;
`;

const SelectionText = styled.div`
  font-size: 14px;
`;

function Item({
  currency,
  miniSwap,
  usdt,
}) {
  const {
    account,
    getBlockNumber,
  } = useWallet();
  const blockNumber = getBlockNumber();
  const [loading, setLoading] = useState(false);
  const [rateLoading, setRateLoading] = useState(false);
  const [rate2Loading, setRate2Loading] = useState(false);
  const [price, setPrice] = useState(new Big(0));
  const [rate, setRate] = useState(0);
  const [rate2, setRate2] = useState(0);

  const balance = useERC20Balance(currency.contract, MINI_SWAP_ADDRESS);

  useEffect(() => {
    const fetch = async () => {
      try {
        const val = await miniSwap.methods.tokenPrices(currency.address).call();
        const rate = await miniSwap.methods.tokenRewardRates(currency.address).
          call();
        const rate2 = await miniSwap.methods.tokenTwoRewardRates(
          currency.address).
          call();
        setPrice(new Big(val));
        setRate(rate);
        setRate2(rate2);
      } catch (e) {
        console.log(e);
      }
    };

    if (miniSwap && currency) {
      fetch();
    }

    return () => {};
  }, [
    miniSwap,
    currency,
    blockNumber,
  ]);

  const handleSetPrice = useCallback(async () => {
    setLoading(true);
    try {
      const r = prompt('设置价格：请输入价格');

      if (r) {
        await miniSwap.methods.setTokenPrice(
          currency.address, fromAmount(r, usdt.decimals)).
          send({ from: account });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    currency,
    miniSwap,
    usdt,
    account,
  ]);

  const handleSetRate = useCallback(async () => {
    setRateLoading(true);
    try {
      const r = prompt('设置返利比例：请输入返利比例，输入0-1000的数字，表示千分之几');

      if (r) {
        await miniSwap.methods.setTokenRewardRates(currency.address, r).
          send({ from: account });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRateLoading(false);
    }
  }, [
    currency,
    miniSwap,
    account,
  ]);

  const handleSetRate2 = useCallback(async () => {
    setRate2Loading(true);
    try {
      const r = prompt('设置二级返利比例：请输入返利比例，输入0-1000的数字，表示千分之几');

      if (r) {
        await miniSwap.methods.setTokenTwoRewardRates(currency.address, r).
          send({ from: account });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRate2Loading(false);
    }
  }, [
    currency,
    miniSwap,
    account,
  ]);

  return (
    <TokenItemWrapper>
      <CurrencyLogo
        size={ '24px' }
        src={ currency.icon } />
      <Column>
        <div>{ currency.name }</div>
        <ListItemName>
          余额: { toAmount(balance, currency.decimals).
          toFixed(4, 0) } { currency.symbol }
        </ListItemName>
      </Column>

      <Column>
        <ValueText>
          价格: { usdt ? `${ toAmount(price, usdt.decimals).
          toLocaleString() } ${ usdt.symbol }` : '~' }
        </ValueText>
        <ValueText>
          一级返利: { rate }/1000
        </ValueText>
        <ValueText>
          二级返利: { rate2 }/1000
        </ValueText>
      </Column>


      <ListItemValue>
        <Column>
          <ButtonEmpty padding={ '0' } onClick={ handleSetPrice }
                       disabled={ loading }>
            { loading ? <Loader /> : '设置' }
          </ButtonEmpty>

          <ButtonEmpty padding={ '0' } onClick={ handleSetRate }
                       disabled={ rateLoading }>
            { rateLoading ? <Loader /> : '设置' }
          </ButtonEmpty>
          <ButtonEmpty padding={ '0' } onClick={ handleSetRate2 }
                       disabled={ rate2Loading }>
            { rate2Loading ? <Loader /> : '设置' }
          </ButtonEmpty>
        </Column>
      </ListItemValue>
    </TokenItemWrapper>
  );
}

function SwapItems({
  tokens,
  miniSwap,
  usdt,
}) {
  return (
    <AutoColumn gap={ '8px' }>
      <SelectionText>
        币种列表
      </SelectionText>
      { tokens.map(
        currency => <Item key={ currency.address } miniSwap={ miniSwap }
                          currency={ currency } usdt={ usdt } />) }
    </AutoColumn>
  );
}

export default SwapItems;
