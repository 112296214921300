import AppBody from '../AppBody';
import AppHeader, { AppWrapper } from '../../components/AppView';
import { Trans } from '@lingui/macro';
import { useMiniSwap } from '../../hooks/useContract';
import { MINI_SWAP_ADDRESS, USDT_ADDRESS } from '../../constants/address';
import { useCallback, useEffect, useState } from 'react';
import useTokens from '../../hooks/useTokens';
import SwapItems from './components/SwapItems';
import useToken from '../../hooks/useToken';
import {
  ButtonEmpty,
} from '../../components/Button';
import { AutoColumn } from '../../components/Column';
import TokenSelectorModal from '../../components/TokenSelectorModal';
import { ArrowLeft, Plus } from 'react-feather';
import { shortenAddress } from '../../utils';
import CopyHelper from '../../components/AccountDetails/Copy';
import Row from '../../components/Row';
import { fromAmount } from '../../utils/amount';
import { useWallet } from 'use-wallet';
import Loader from '../../components/Loader';
import WithdrawButton from './components/WithdrawButton';
import { Link } from 'react-router-dom';

function SwapConsole() {
  const miniSwap = useMiniSwap(MINI_SWAP_ADDRESS);

  const { account } = useWallet();
  const [outAddresses, setOutAddresses] = useState([]);
  const [outTokens] = useTokens(outAddresses);
  const [usdt] = useToken(USDT_ADDRESS);
  const [isOpen, setIsOpen] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const fetchTokens = useCallback(async () => {
    setLoading(true);
    try {
      const data = await miniSwap.methods.getSwapTokens().call();
      setOutAddresses(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [miniSwap]);

  const handleOnSelect = useCallback(async (token) => {
    setAddLoading(true);
    try {
      const r = prompt('输入价格');

      if (r) {
        await miniSwap.methods.setTokenPrice(
          token.address, fromAmount(r, usdt.decimals)).send({ from: account });

        window.alert('添加成功');

        setTimeout(fetchTokens, 4000);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setAddLoading(false);
    }
  }, [
    account,
    fetchTokens,
    miniSwap,
    usdt,
  ]);

  useEffect(() => {
    if (miniSwap) {
      fetchTokens();
    }
    return () => {};
  }, [
    miniSwap,
    fetchTokens,
  ]);

  return (
    <AppBody>
      <AppHeader leftContent={ <Link to={ '/console' }><ArrowLeft /></Link> }
                 rightContent={ <ButtonEmpty padding={ '0' }
                                             disabled={ addLoading }
                                             onClick={ () => setIsOpen(true) }>
                   { addLoading ? <Loader /> : <Plus /> }
                 </ButtonEmpty> }
      >
        <Trans>Swap</Trans>配置
      </AppHeader>

      <AppWrapper>
        <AutoColumn gap={ '24px' }>
          <Row>
            合约地址 { shortenAddress(MINI_SWAP_ADDRESS) } <CopyHelper
            toCopy={ MINI_SWAP_ADDRESS } />
          </Row>

          { loading ? (
            <Row justify={ 'center' }><Loader /></Row>
          ) : (
            <SwapItems
              tokens={ outTokens } miniSwap={ miniSwap } usdt={ usdt } />
          ) }

          <WithdrawButton miniSwap={ miniSwap } />
        </AutoColumn>
      </AppWrapper>

      <TokenSelectorModal tokens={ outTokens } isOpen={ isOpen }
                          onDismiss={ () => setIsOpen(false) }
                          onSelect={ handleOnSelect } />
    </AppBody>
  );
}

export default SwapConsole;
