import useWeb3 from './useWeb3';
import Big from 'big.js';
import { useEffect, useState } from 'react';
import { useWallet } from 'use-wallet';

function useERC20Balance(contract, account) {
  const web3 = useWeb3();
  const [value, setValue] = useState(new Big(0));
  const { getBlockNumber } = useWallet();
  const blockNumber = getBlockNumber();

  useEffect(() => {
    const fetch = async () => {
      try {
        setValue(new Big(await contract.methods.balanceOf(account).call()));
      } catch (e) {
        console.log(e);
      }
    };
    if (web3 && contract) {
      fetch();
    }
    return () => {};
  }, [
    web3,
    contract,
    setValue,
    account,
    blockNumber,
  ]);

  return value;
}

export default useERC20Balance;
