import { Trans } from '@lingui/macro';
import { CHAIN_INFO } from '../../constants/chainInfo';
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from '../../constants/chains';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowDownCircle, ChevronDown } from 'react-feather';
import styled from 'styled-components/macro';
import { ExternalLink, MEDIA_WIDTHS } from '../../theme';
import useParsedQueryString from '../../hooks/useParsedQueryString';
import { useWallet } from 'use-wallet';
import useWeb3Provider from '../../hooks/useWeb3Provider';
import { ethers } from 'ethers';

const ActiveRowLinkList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;

  & > a {
    align-items: center;
    color: ${ ({ theme }) => theme.text2 };
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    justify-content: space-between;
    padding: 8px 0 4px;
    text-decoration: none;
  }

  & > a:first-child {
    margin: 0;
    margin-top: 0px;
    padding-top: 10px;
  }
`;
const ActiveRowWrapper = styled.div`
  background-color: ${ ({ theme }) => theme.bg1 };
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
  width: 100%;
`;
const FlyoutHeader = styled.div`
  color: ${ ({ theme }) => theme.text2 };
  font-weight: 400;
`;
const FlyoutMenu = styled.div`
  position: absolute;
  top: 54px;
  width: 272px;
  z-index: 99;
  padding-top: 10px;
  @media screen and (min-width: ${ MEDIA_WIDTHS.upToSmall }px) {
    top: 40px;
  }
`;
const FlyoutMenuContents = styled.div`
  align-items: flex-start;
  background-color: ${ ({ theme }) => theme.bg0 };
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
  padding: 16px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;
const FlyoutRow = styled.div`
  align-items: center;
  background-color: ${ ({
    active,
    theme,
  }) => (
          active ? theme.bg1 : 'transparent'
  ) };
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 8px;
  text-align: left;
  width: 100%;
`;
const FlyoutRowActiveIndicator = styled.div`
  background-color: ${ ({ theme }) => theme.green1 };
  border-radius: 50%;
  height: 9px;
  width: 9px;
`;
const LinkOutCircle = styled(ArrowDownCircle)`
  transform: rotate(230deg);
  width: 16px;
  height: 16px;
`;
const Logo = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;
const NetworkLabel = styled.div`
  flex: 1 1 auto;
`;
const SelectorLabel = styled(NetworkLabel)`
  display: none;
  @media screen and (min-width: ${ MEDIA_WIDTHS.upToSmall }px) {
    display: block;
    margin-right: 8px;
  }
`;
const SelectorControls = styled.div`
  align-items: center;
  background-color: ${ ({ theme }) => theme.bg0 };
  border: 2px solid ${ ({ theme }) => theme.bg0 };
  border-radius: 16px;
  color: ${ ({ theme }) => theme.text1 };
  cursor: ${ ({ interactive }) => (
          interactive ? 'pointer' : 'auto'
  ) };
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  padding: 6px 8px;
`;
const SelectorLogo = styled(Logo)`
  margin-right: ${ ({ interactive }) => (
          interactive ? 8 : 0
  ) }px;
  @media screen and (min-width: ${ MEDIA_WIDTHS.upToSmall }px) {
    margin-right: 8px;
  }
`;
const SelectorWrapper = styled.div`
  @media screen and (min-width: ${ MEDIA_WIDTHS.upToSmall }px) {
    position: relative;
  }
`;
const StyledChevronDown = styled(ChevronDown)`
  width: 16px;
`;

const ExplorerLabel = ({ chainId }) => {
  switch (chainId) {
    case SupportedChainId.ARBITRUM_ONE:
    case SupportedChainId.ARBITRUM_RINKEBY:
      return <Trans>Arbiscan</Trans>;
    case SupportedChainId.OPTIMISM:
    case SupportedChainId.OPTIMISTIC_KOVAN:
      return <Trans>Optimistic Etherscan</Trans>;
    case SupportedChainId.POLYGON:
    case SupportedChainId.POLYGON_MUMBAI:
      return <Trans>Polygonscan</Trans>;
    case SupportedChainId.BSC:
      return <Trans>Bscscan</Trans>;
    default:
      return <Trans>Etherscan</Trans>;
  }
};

function Row({
  targetChain,
  onSelectChain,
}) {
  const {
    chainId,
  } = useWallet();
  if (!chainId) {
    return null;
  }

  const active = chainId === targetChain;
  const {
    explorer,
    label,
    logoUrl,
  } = CHAIN_INFO[targetChain];

  const rowContent = (
    <FlyoutRow onClick={ () => onSelectChain(targetChain) } active={ active }>
      <Logo src={ logoUrl } />
      <NetworkLabel>{ label }</NetworkLabel>
      { chainId === targetChain && <FlyoutRowActiveIndicator /> }
    </FlyoutRow>
  );

  if (active) {
    return (
      <ActiveRowWrapper>
        { rowContent }
        <ActiveRowLinkList>
          { explorer ? (
            <ExternalLink href={ explorer }>
              <ExplorerLabel chainId={ chainId } /> <LinkOutCircle />
            </ExternalLink>
          ) : null }
        </ActiveRowLinkList>
      </ActiveRowWrapper>
    );
  }
  return rowContent;
}

const getParsedChainId = (parsedQs) => {
  const chain = parsedQs?.chain;
  if (!chain || typeof chain !== 'string') return {
    urlChain: undefined,
    urlChainId: undefined,
  };

  return {
    urlChain: chain.toLowerCase(),
    urlChainId: getChainIdFromName(chain),
  };
};

const getChainIdFromName = (name) => {
  const entry = Object.entries(CHAIN_IDS_TO_NAMES).find(([_, n]) => n === name);
  const chainId = entry?.[0];
  return chainId ? parseInt(chainId) : undefined;
};

export default function NetworkSelector() {
  const {
    chainId,
    ethereum,
  } = useWallet();
  const parsedQs = useParsedQueryString();
  const {
    urlChain,
    urlChainId,
  } = getParsedChainId(parsedQs);
  const node = useRef();
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useOnClickOutside(node, open ? toggle : undefined);

  const info = chainId ? CHAIN_INFO[chainId] : undefined;

  const { switchChain: switchToNetwork } = useWeb3Provider();

  const handleChainSwitch = useCallback((targetChain, skipToggle) => {
    console.log(targetChain, skipToggle);

    switchToNetwork({ chainId: ethers.utils.hexlify(targetChain) });
  }, [switchToNetwork]);

  // set chain parameter on initial load if not there
  useEffect(() => {
    if (chainId && !urlChainId) {
      // todo
    }
  }, [
    chainId,
    urlChainId,
    urlChain,
  ]);

  if (!chainId || !info || !ethereum) {
    return null;
  }

  return (
    <SelectorWrapper ref={ node } onMouseEnter={ toggle }
                     onMouseLeave={ toggle }>
      <SelectorControls interactive>
        <SelectorLogo interactive src={ info.logoUrl } />
        <SelectorLabel>{ info.label }</SelectorLabel>
        <StyledChevronDown />
      </SelectorControls>
      { open && (
        <FlyoutMenu>
          <FlyoutMenuContents>
            <FlyoutHeader>
              <Trans>Select a network</Trans>
            </FlyoutHeader>
            <Row onSelectChain={ handleChainSwitch }
                 targetChain={ SupportedChainId.BSC } />
            {/*<Row onSelectChain={ handleChainSwitch }*/ }
            {/*     targetChain={ SupportedChainId.MAINNET } />*/ }
            {/*<Row onSelectChain={ handleChainSwitch }*/ }
            {/*     targetChain={ SupportedChainId.POLYGON } />*/ }
          </FlyoutMenuContents>
        </FlyoutMenu>
      ) }
    </SelectorWrapper>
  );
}
