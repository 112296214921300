import { Trans } from '@lingui/macro';
import { darken } from 'polished';
import { Activity } from 'react-feather';
import styled, { css } from 'styled-components/macro';
import { UnsupportedChainIdError } from '@web3-react/core';

import { shortenAddress } from '../../utils';
import { ButtonSecondary } from '../Button';
import { useWallet } from 'use-wallet';
import WalletModal from '../WalletModal';
import useWalletModal from '../../hooks/useWalletModal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultChainId } from '../../constants/chains';

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${ ({ theme }) => theme.flexRowNoWrap }
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 14px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 1px;

  &:focus {
    outline: none;
  }
`;

const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${ ({ theme }) => theme.red1 };
  border: 1px solid ${ ({ theme }) => theme.red1 };
  color: ${ ({ theme }) => theme.white };
  font-weight: 500;

  &:hover, &:focus {
    background-color: ${ ({ theme }) => darken(0.1, theme.red1) };
  }
`;

const Web3StatusConnect = styled(Web3StatusGeneric)`
  background-color: ${ ({ theme }) => theme.primary4 };
  border: none;

  color: ${ ({ theme }) => theme.primaryText1 };
  font-weight: 500;

  &:hover, &:focus {
    border: 1px solid ${ ({ theme }) => darken(0.05, theme.primary4) };
    color: ${ ({ theme }) => theme.primaryText1 };
  }

  ${ ({ faded }) => faded && css`
    background-color: ${ ({ theme }) => theme.primary5 };
    border: 1px solid ${ ({ theme }) => theme.primary5 };
    color: ${ ({ theme }) => theme.primaryText1 };

    :hover,
    :focus {
      border: 1px solid ${ ({ theme }) => darken(0.05, theme.primary4) };
      color: ${ ({ theme }) => darken(0.05, theme.primaryText1) };
    }
  ` }
`;

const Web3StatusConnected = styled(Web3StatusGeneric)`
  background-color: ${ ({
    pending,
    theme,
  }) => (
          pending ? theme.primary1 : theme.bg1
  ) };
  border: 1px solid ${ ({
    pending,
    theme,
  }) => (
          pending ? theme.primary1 : theme.bg1
  ) };
  color: ${ ({
    pending,
    theme,
  }) => (
          pending ? theme.white : theme.text1
  ) };
  font-weight: 500;

  &:hover, &:focus {
    border: 1px solid ${ ({ theme }) => darken(0.05, theme.bg3) };

    &:focus {
      border: 1px solid ${ ({
        pending,
        theme,
      }) => (
              pending ? darken(0.1, theme.primary1) : darken(0.1, theme.bg2)
      ) };
    }
  }
`;

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`;

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`;

function Web3StatusInner() {
  const {
    account,
    error,
    chainId,
  } = useWallet();
  const { setWalletModal } = useWalletModal();

  const [chainError, setChanError] = useState(false);

  const moError = useMemo(() => {
    if (!error && chainError) {
      return chainError;
    }
    return error;
  }, [
    chainError,
    error,
  ]);

  const openWalletModal = useCallback(() => {
    setWalletModal(true);
  }, [setWalletModal]);

  useEffect(() => {
    if (account && chainId !== DefaultChainId) {
      setChanError(true);
    } else {
      setChanError(false);
    }
  }, [
    chainId,
    account,
  ]);

  if (account && !chainError) {
    return (
      <Web3StatusConnected id="web3-status-connected"
                           onClick={ openWalletModal }>
        <Text>{ shortenAddress(account) }</Text>
      </Web3StatusConnected>
    );
  } else if (moError) {
    return (
      <Web3StatusError onClick={ openWalletModal }>
        <NetworkIcon />
        { moError === true ? <Trans>Wrong
          Network</Trans> : <Trans>Error connecting</Trans> }
      </Web3StatusError>
    );
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={ openWalletModal }
                         faded={ !account }>
        <Text>
          <Trans>Connect Wallet</Trans>
        </Text>
      </Web3StatusConnect>
    );
  }
}

export default function Web3Status() {
  return (
    <>
      <Web3StatusInner />
      <WalletModal />
    </>
  );
}
