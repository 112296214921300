import ThemeProvider, { ThemedGlobalStyle } from './theme';
import { HashRouter } from 'react-router-dom';
import { LanguageProvider } from './i18n';
import AppState from './contexts/AppState';
import Web3Provider from './contexts/Web3Provider';
import Web3Context from './contexts/Web3Context';
import UserState from './contexts/UserState';

function Providers({ children }) {
  return (
    <AppState>
      <Web3Provider>
        <Web3Context>
          <UserState>

            <HashRouter>
              <LanguageProvider>
                <ThemeProvider>
                  <ThemedGlobalStyle />
                  { children }
                </ThemeProvider>
              </LanguageProvider>
            </HashRouter>

          </UserState>
        </Web3Context>
      </Web3Provider>
    </AppState>
  );
}

export default Providers;
