import Big from 'big.js'

export function toAmount (value, decimals = 18) {
  return new Big(value).div(new Big(10).pow(decimals))
}

export function toSpecialAmount (value, decimals = 18, showDecimals = 4) {
  const b = toAmount(value, decimals)

  if (b.gt(1)) {
    return b.toFixed(2, 0)
  } else if (b.lte(0)) {
    return b.toFixed(0)
  }
  return b.toFixed(showDecimals)
}

export function fromAmount (value, decimals = 18) {
  return (new Big(value)).times(new Big(10).pow(decimals)).toFixed(0)
}
