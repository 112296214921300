import { useEffect, useState } from 'react';
import useWeb3 from './useWeb3';
import { getERC20Contract, ZERO_ADDRESS } from '../utils/ethereum';
import { Token } from '../utils/types';

function useToken(address) {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);

  const web3 = useWeb3();

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      let token = null;
      try {
        if (address === ZERO_ADDRESS) {
          token = new Token({
            address,
            name: 'Binance Wrapped BNB',
            symbol: 'BNB',
            decimals: 18,
            icon: require('../assets/images/bnb.png'),
          });
        }

        try {
          const erc20 = getERC20Contract(address, web3);
          token = new Token({
            address,
            name: await erc20.methods.name().call(),
            symbol: await erc20.methods.symbol().call(),
            decimals: await erc20.methods.decimals().call(),
            icon: `/tokens/${ address }.png`,
            contract: erc20,
          });
        } catch (e) {
          console.log(e);
          return null;
        }

        if (token.symbol === 'USDT') {
          token.name = 'Binance-Peg USDT Token';
        }

        setToken(token);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    if (web3) {
      fetch();
    }
    return () => {};
  }, [
    web3,
    address,
  ]);

  return [
    token,
    loading,
  ];
}

export default useToken;
