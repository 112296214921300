import styled from 'styled-components';
import { AutoColumn } from '../../../components/Column';
import { AutoRow, RowBetween } from '../../../components/Row';
import useToken from '../../../hooks/useToken';
import { ButtonLight, ButtonPrimary } from '../../../components/Button';
import { fromAmount, toAmount, toSpecialAmount } from '../../../utils/amount';
import CurrencyLogo from '../../../components/CurrencyLogo';
import { Trans } from '@lingui/macro';
import { useWallet } from 'use-wallet';
import { useEffect, useMemo, useState } from 'react';
import { BLOCK_PER_SEC } from '../../../constants/chains';
import Countdown, { zeroPad } from 'react-countdown';
import PoolDepositModal from './PoolDepositModal';
import Big from 'big.js';
import PoolWithdrawModal from './PoolWithdrawModal';
import PoolProfitModal from './PoolProfitModal';


const PoolItemWrapper = styled.div`
  border: 1px solid #eaeaea;
  border-radius: 12px;
  background-color: white;
  padding: 1em;
  min-height: 358.5px;
  font-size: 14px;
`;

const APYText = styled.div`
  color: ${ ({ theme }) => theme.yellow2 };
  font-size: 20px;
`;

const ItemActionWrapper = styled.div`
  display: flex;
  margin-top: 12px;

  & > * {
    flex: 1;
    margin-right: 8px;
  }

  & > *:last-child {
    margin-right: unset;
  }
`;

const ValueText = styled.div`
  font-size: 14px;
`;

const HR = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${ ({ theme }) => theme.bg2 };
`;

function PoolItem({
  pool,
  miniPool,
}) {
  const {
    account,
    getBlockNumber,
  } = useWallet();
  const blockNumber = getBlockNumber();

  const [depositOpen, setDepositOpen] = useState(false);
  const [withdrawOpen, setWithdrawOpen] = useState(false);
  const [profitOpen, setProfitOpen] = useState(false);

  const [token] = useToken(pool.token);
  const [pledgeToken] = useToken(pool.pledgeToken);


  const [pending, setPending] = useState(new Big(0));
  const [userInfo, setUserInfo] = useState(null);

  const endTime = useMemo(() => {
    const blocks = parseInt(pool.endRewardBlock) - blockNumber;
    if (blocks <= 0) {
      return Date.now();
    }
    return Date.now() + (
      blocks * BLOCK_PER_SEC * 1000
    );
  }, [
    pool,
    blockNumber,
  ]);

  const deposited = useMemo(() => {
    if (!userInfo) {
      return new Big(0);
    }
    return new Big(userInfo.amount);
  }, [userInfo]);

  const apy = useMemo(() => {
    if (!pledgeToken || pool.supply <= 0) {
      return null;
    }

    const one = new Big(fromAmount(1, pledgeToken.decimals));
    const r = one.div(pool.supply);
    const TOKEN_YEAR = new Big(pool.tokenPerBlock).mul(
      new Big(3600 * 24 * 365).div(BLOCK_PER_SEC));

    const rTOKEN_YEAR = r.mul(TOKEN_YEAR);

    return rTOKEN_YEAR.div(one).mul(100).toFixed(2);
  }, [
    pledgeToken,
    pool,
  ]);

  const pendingToken = useMemo(() => {
    if (pool && pending) {
      const p1 = pending.mul(pool.awardRate).div(1000);
      const p2 = pending.mul(pool.awardRate2).div(1000);

      return pending.sub(p1).sub(p2);
    }

    return new Big(0);
  }, [
    pending,
    pool,
  ]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const val = await miniPool.methods.pendingToken(pool.id, account).
          call();
        setPending(new Big(val));
      } catch (e) {
        console.log(e);
      }
    };

    if (miniPool && account) {
      fetch();
    }
    return () => {};
  }, [
    blockNumber,
    miniPool,
    account,
    pool,
  ]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const val = await miniPool.methods.userInfo(pool.id, account).
          call();
        setUserInfo(val);
      } catch (e) {
        console.log(e);
      }
    };

    if (miniPool && account) {
      fetch();
    }
    return () => {};
  }, [
    blockNumber,
    miniPool,
    account,
    pool,
  ]);

  // Renderer callback with condition
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      return <div><Trans>Over</Trans></div>;
    } else {
      return (
        <span>
          { days > 0 ? `${ zeroPad(days) }:` : null }
          { zeroPad(hours) }:{ zeroPad(minutes) }:{ zeroPad(seconds) }
        </span>
      );
    }
  };

  return (
    <PoolItemWrapper>
      { pool && token && pledgeToken ? (
        <AutoColumn gap={ '12px' }>
          <RowBetween justify={ 'space-between' }>
            <AutoRow gap={ '8px' }>
              <CurrencyLogo src={ token.icon } size={ '48px' } />
              <AutoColumn>
                <div style={ { fontSize: '16px' } }>
                  <Trans>Deposit</Trans> { pledgeToken.symbol }
                </div>
                <div style={ { fontSize: '16px' } }>
                  <Trans>Earn</Trans> { token.symbol }
                </div>
              </AutoColumn>
            </AutoRow>
            <div>
              #{ pool.id }
            </div>
          </RowBetween>

          <HR />

          <AutoRow justify={ 'space-between' }>
            <div>
              <Trans>APY</Trans>
            </div>
            <APYText>{ apy ? (apy>1000? '2'+(''+apy).substring(0,2)+'.'+(''+apy).substring(2,4):apy) : '~' }%</APYText>
          </AutoRow>

          <AutoRow justify={ 'space-between' }>
            <div>
              <Trans>Total Deposited</Trans>
            </div>
            <ValueText>
              { toSpecialAmount(
                pool.supply, pledgeToken.decimals) } { pledgeToken.symbol }
            </ValueText>
          </AutoRow>

          <AutoRow justify={ 'space-between' }>
            <div>
              <Trans>Rewards Per Block</Trans>
            </div>
            <ValueText>
              { toAmount(pool.tokenPerBlock, token ? token.decimals : 18).
                toLocaleString() } { token.symbol }
            </ValueText>
          </AutoRow>

          {/*<AutoRow justify={ 'space-between' }>*/ }
          {/*  <div>*/ }
          {/*    <Trans>Invitation Reward</Trans>*/ }
          {/*  </div>*/ }
          {/*  <ValueText>{ new Big(pool.awardRate).div(1000).*/ }
          {/*    mul(100).*/ }
          {/*    toFixed(2) }%</ValueText>*/ }
          {/*</AutoRow>*/ }

          {/*<AutoRow justify={ 'space-between' }>*/ }
          {/*  <div>*/ }
          {/*    <Trans>Friendship Reward</Trans>*/ }
          {/*  </div>*/ }
          {/*  <ValueText>{ new Big(pool.awardRate2).div(1000).*/ }
          {/*    mul(100).*/ }
          {/*    toFixed(2) }%</ValueText>*/ }
          {/*</AutoRow>*/ }

          <AutoRow justify={ 'space-between' }>
            <div>
              <Trans>Countdown</Trans>
            </div>
            <ValueText>
              <Countdown
                date={ endTime }
                renderer={ renderer }
              />
            </ValueText>
          </AutoRow>

          <AutoRow justify={ 'space-between' }>
            <div>
              <Trans>Deposit Restrictions</Trans>
            </div>
            <ValueText>
              { pool.limit !== '0' ? <>
                { toSpecialAmount(
                  pool.limit, pledgeToken.decimals) } { pledgeToken.symbol }
              </> : <Trans>Not Limit</Trans> }
            </ValueText>
          </AutoRow>

          <HR />

          <AutoRow justify={ 'space-between' }>
            <div>
              <Trans>Pending Token</Trans>
            </div>
            <ValueText>
              { toSpecialAmount(pendingToken, token.decimals) } { token.symbol }
            </ValueText>
          </AutoRow>

          <AutoRow justify={ 'space-between' }>
            <div>
              <Trans>Deposited</Trans>
            </div>
            <ValueText>
              { toSpecialAmount(
                deposited, pledgeToken.decimals) } { pledgeToken.symbol }
            </ValueText>
          </AutoRow>

          <ItemActionWrapper>
            <ButtonPrimary padding={ '8px' }
                           onClick={ () => setDepositOpen(true) }
                           disabled={ Date.now() >= endTime }>
              <Trans>Deposit</Trans>
            </ButtonPrimary>
            <ButtonLight padding={ '8px' }
                         onClick={ () => setProfitOpen(true) }>
              <Trans>Profit</Trans>
            </ButtonLight>
            <ButtonLight padding={ '8px' }
                         onClick={ () => setWithdrawOpen(true) }
                         disabled={ Date.now() < endTime }>
              <Trans>Withdraw</Trans>
            </ButtonLight>
          </ItemActionWrapper>
        </AutoColumn>
      ) : null }

      <PoolDepositModal isOpen={ depositOpen } setIsOpen={ setDepositOpen }
                        depositToken={ pledgeToken }
                        userInfo={ userInfo }
                        pool={ pool } miniPool={ miniPool } />

            <PoolProfitModal isOpen={ profitOpen } setIsOpen={ setProfitOpen }
                        depositToken={ token }
                        userInfo={ userInfo }
                        pool={ pool } miniPool={ miniPool } />

      <PoolWithdrawModal isOpen={ withdrawOpen } setIsOpen={ setWithdrawOpen }
                         depositToken={ pledgeToken }
                         userInfo={ userInfo }
                         pending={ pending }
                         pool={ pool } miniPool={ miniPool } />
    </PoolItemWrapper>
  );
}

export default PoolItem;
