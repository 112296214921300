import styled from 'styled-components';
import { useCallback, useMemo, useState } from 'react';
import { LoadingOpacityContainer, loadingOpacityMixin } from '../Loader/styles';
import NumericalInput from '../NumericalInput';
import { ButtonGray } from '../Button';
import { darken } from 'polished';
import { RowBetween, RowFixed } from '../Row';
import CurrencyLogo from '../CurrencyLogo';
import useTheme from '../../hooks/useTheme';
import { useWallet } from 'use-wallet';
import { ThemedText } from '../../theme';
import { Trans } from '@lingui/macro';
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg';
import TokenSelectorModal from '../TokenSelectorModal';
import { toAmount, toSpecialAmount } from '../../utils/amount';

const InputPanel = styled.div`
  ${ ({ theme }) => theme.flexColumnNoWrap }
  position: relative;
  border-radius: ${ ({ hideInput }) => (
          hideInput ? '16px' : '20px'
  ) };
  background-color: ${ ({
    theme,
    hideInput,
  }) => (
          hideInput ? 'transparent' : theme.bg2
  ) };
  z-index: 1;
  width: ${ ({ hideInput }) => (
          hideInput ? '100%' : 'initial'
  ) };
  transition: height 1s ease;
  will-change: height;
`;

const Container = styled.div`
  border-radius: ${ ({ hideInput }) => (
          hideInput ? '16px' : '20px'
  ) };
  border: 1px solid ${ ({ theme }) => theme.bg0 };
  background-color: ${ ({ theme }) => theme.bg1 };
  width: ${ ({ hideInput }) => (
          hideInput ? '100%' : 'initial'
  ) };

  :focus,
  :hover {
    border: 1px solid ${ ({
      theme,
      hideInput,
    }) => (
            hideInput ? ' transparent' : theme.bg3
    ) };
  }
`;

const InputRow = styled.div`
  ${ ({ theme }) => theme.flexRowNoWrap }
  align-items: center;
  justify-content: space-between;
  padding: ${ ({
    selected,
    hideInput,
  }) => (
          hideInput ? '0' : (
                  selected ? '1rem 1rem 0.75rem 1rem' : '1rem 1rem 1rem 1rem'
          )
  ) };
`;

const CurrencySelect = styled(ButtonGray)`
  align-items: center;
  background-color: ${ ({
    selected,
    theme,
  }) => (
          selected ? theme.bg2 : theme.primary1
  ) };
  box-shadow: ${ ({ selected }) => (
          selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)'
  ) };
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  color: ${ ({
    selected,
    theme,
  }) => (
          selected ? theme.text1 : theme.white
  ) };
  cursor: pointer;
  border-radius: 16px;
  outline: none;
  user-select: none;
  border: none;
  font-size: 24px;
  font-weight: 500;
  height: ${ ({ hideInput }) => (
          hideInput ? '2.8rem' : '2.4rem'
  ) };
  width: ${ ({ hideInput }) => (
          hideInput ? '100%' : 'initial'
  ) };
  padding: 0 8px;
  justify-content: space-between;
  margin-left: ${ ({ hideInput }) => (
          hideInput ? '0' : '12px'
  ) };

  &:focus,
  &:hover {
    background-color: ${ ({
      selected,
      theme,
    }) => (
            selected ? theme.bg3 : darken(0.05, theme.primary1)
    ) };
  }

  visibility: ${ ({ visible }) => (
          visible ? 'visible' : 'hidden'
  ) };
`;

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LabelRow = styled.div`
  ${ ({ theme }) => theme.flexRowNoWrap }
  align-items: center;
  color: ${ ({ theme }) => theme.text1 };
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 1rem 1rem;

  span:hover {
    cursor: pointer;
    color: ${ ({ theme }) => darken(0.2, theme.text2) };
  }
`;

const FiatRow = styled(LabelRow)`
  justify-content: flex-end;
`;

const StyledTokenName = styled.span`
  ${ ({ active }) => (
          active
                  ? '  margin: 0 0.25rem 0 0.25rem;'
                  : '  margin: 0 0.25rem 0 0.25rem;'
  ) }
  font-size: ${ ({ active }) => (
          active ? '16px' : '16px'
  ) };
`;

const StyledBalanceMax = styled.button`
  background-color: ${ ({ theme }) => theme.primary5 };
  border: none;
  border-radius: 12px;
  color: ${ ({ theme }) => theme.primary1 };
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  margin-left: 0.25rem;
  opacity: ${ ({ disabled }) => (
          !disabled ? 1 : 0.4
  ) };
  padding: 4px 6px;
  pointer-events: ${ ({ disabled }) => (
          !disabled ? 'initial' : 'none'
  ) };

  :hover {
    opacity: ${ ({ disabled }) => (
            !disabled ? 0.8 : 0.4
    ) };
  }

  :focus {
    outline: none;
  }
`;

const StyledNumericalInput = styled(NumericalInput)`
  ${ loadingOpacityMixin };
  text-align: left;
`;

const StyledDropDown = styled(DropDown)`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;

  path {
    stroke: ${ ({
      selected,
      theme,
    }) => (
            selected ? theme.text1 : theme.white
    ) };
    stroke-width: 1.5px;
  }
`;

function CurrencyInputPanel({
  tokens,
  showMaxButton,
  hideBalance,
  loading,
  value,
  onValueChange,
  currency,
  onCurrencyChange,
  currencyBalance,
  inputDisabled,
  hideInput = false,
}) {
  const { account } = useWallet();
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const selected = useMemo(() => {
    return !!currency;
  }, [currency]);

  const onMax = useCallback(() => {
    if (showMaxButton) {
      onValueChange(toAmount(currencyBalance, currency.decimals));
    }
  }, [
    showMaxButton,
    onValueChange,
    currencyBalance,
    currency,
  ]);

  return (
    <InputPanel hideInput={ hideInput }>
      <Container hideInput={ hideInput }>
        <InputRow selected={ selected } hideInput={ hideInput }>
          <StyledNumericalInput
            value={ value }
            onUserInput={ onValueChange }
            $loading={ loading }
            disabled={ inputDisabled }
          />

          <CurrencySelect visible={ true }
                          hideInput={ hideInput }
                          selected={ selected }
                          onClick={ () => setIsOpen(true) }>
            <Aligner>
              <RowFixed>
                { currency ? (
                  <CurrencyLogo style={ { marginRight: '0.5rem' } }
                                src={ currency.icon }
                                size={ '24px' } />
                ) : null }

                <StyledTokenName>
                  { currency ? currency.symbol : <Trans>Select Token</Trans> }
                </StyledTokenName>
              </RowFixed>
              <StyledDropDown selected={ selected } />
            </Aligner>
          </CurrencySelect>
        </InputRow>

        { !hideBalance && currency && (
          <FiatRow>
            <RowBetween>
              <LoadingOpacityContainer $loading={ loading }>

              </LoadingOpacityContainer>
              { account ? (
                <RowFixed style={ { height: '17px' } }>
                  <ThemedText.Body
                    onClick={ onMax }
                    color={ theme.text3 }
                    fontWeight={ 500 }
                    fontSize={ 14 }
                    style={ {
                      display: 'inline',
                      cursor: 'pointer',
                    } }
                  >
                    { currency && currencyBalance ? (
                      <Trans>Balance: { toSpecialAmount(currencyBalance,
                        currency.decimals,
                      ) }</Trans>
                    ) : null }
                  </ThemedText.Body>

                  { showMaxButton ? (
                    <StyledBalanceMax onClick={ onMax }>
                      <Trans>MAX</Trans>
                    </StyledBalanceMax>
                  ) : null }
                </RowFixed>
              ) : (
                <span />
              ) }
            </RowBetween>
          </FiatRow>
        ) }
      </Container>

      <TokenSelectorModal isOpen={ isOpen }
                          tokens={ tokens }
                          onDismiss={ () => setIsOpen(false) }
                          onSelect={ onCurrencyChange } />
    </InputPanel>
  );
}

export default CurrencyInputPanel;
