import AppHeader, { AppWrapper } from '../../components/AppView';
import AppBody from '../AppBody';
import { useMiniSwap } from '../../hooks/useContract';
import {
  ETH_ADDRESS, MINI_SWAP_ADDRESS, USDT_ADDRESS,
} from '../../constants/address';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ButtonLight, ButtonPrimary,
} from '../../components/Button';
import CurrencyInputPanel from '../../components/CurrencyInputPanel';
import { AutoColumn } from '../../components/Column';
import styled, { useTheme } from 'styled-components';
import { ArrowDown } from 'react-feather';
import { Trans } from '@lingui/macro';
import useTokens from '../../hooks/useTokens';
import Loader from '../../components/Loader';
import { fromAmount, toAmount } from '../../utils/amount';
import useERC20Allowance from '../../hooks/useERC20Allowance';
import { useWallet } from 'use-wallet';
import useERC20Approve from '../../hooks/useERC20Approve';
import useERC20Balance from '../../hooks/useERC20Balance';
import Big from 'big.js';
import { ZERO_ADDRESS } from '../../utils/ethereum';
import Modal from '../../components/Modal';
import { LoadingView, SubmittedView } from '../../components/ModalViews';
import PromoteBanner from '../../components/PromoteBanner';
import useUserState from '../../hooks/useUserState';

const ArrowWrapper = styled.div`
  padding: 4px;
  border-radius: 12px;
  height: 32px;
  width: 32px;
  position: relative;
  margin-top: -18px;
  margin-bottom: -18px;
  left: calc(50% - 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${ ({ theme }) => theme.bg1 };
  border: 4px solid;
  border-color: ${ ({ theme }) => theme.bg0 };
  z-index: 2;
`;

function Swap() {
  const theme = useTheme();
  const miniSwap = useMiniSwap(MINI_SWAP_ADDRESS);
  const {
    referrer,
    user,
  } = useUserState();
  const {
    account,
    balance,
  } = useWallet();

  const [inputAddresses, setInputAddresses] = useState([
    ZERO_ADDRESS,
  ]);
  const [outAddresses, setOutAddresses] = useState([]);

  const [inputTokens] = useTokens(inputAddresses);
  const [outTokens] = useTokens(outAddresses);

  const [inputValue, setInputValue] = useState('');
  const [outValue, setOutValue] = useState('');

  const [inputCurrency, setInputCurrency] = useState(null);
  const [outCurrency, setOutCurrency] = useState(null);

  const inputCurrencyContract = useMemo(() => {
    if (inputCurrency) {
      return inputCurrency.contract;
    }
    return null;
  }, [inputCurrency]);

  const outCurrencyContract = useMemo(() => {
    if (outCurrency) {
      return outCurrency.contract;
    }
    return null;
  }, [outCurrency]);

  const inputBalance = useERC20Balance(inputCurrencyContract, account);
  const outBalance = useERC20Balance(outCurrencyContract, account);
  const outSwapBalance = useERC20Balance(
    outCurrencyContract, MINI_SWAP_ADDRESS);

  const [allowance, fetchAllowance] = useERC20Allowance(
    inputCurrencyContract, account, MINI_SWAP_ADDRESS);

  const [onApprove, approveLoading] = useERC20Approve(
    inputCurrencyContract, MINI_SWAP_ADDRESS);

  const [loading, setLoading] = useState(false);
  const [isSubmitShow, setIsSubmitShow] = useState(false);
  const [submitHash, setSubmitHash] = useState('');
  const [submitError, setSubmitError] = useState('');

  const moInputBalance = useMemo(() => {
    return inputCurrency && inputCurrency.isNative ? balance : inputBalance;
  }, [
    balance,
    inputBalance,
    inputCurrency,
  ]);

  const outputValueEnough = useMemo(
    () => {
      if (outCurrency && outSwapBalance && outValue) {
        return toAmount(outSwapBalance, outCurrency.decimals).gte(outValue);
      }
      return true;
    }, [
      outCurrency,
      outSwapBalance,
      outValue,
    ]);

  useEffect(() => {
    if (inputTokens.length) {
      setInputCurrency(inputTokens.find(e => e.isNative));
    }
  }, [inputTokens]);

  // 获取输出列表
  useEffect(() => {
    const fetch = async () => {
      try {
        const outs = await miniSwap.methods.getSwapTokens().call();
        const ins = await miniSwap.methods.getSwapRouterTokens().call();
        setOutAddresses(outs);
        setInputAddresses([
          ETH_ADDRESS,
          USDT_ADDRESS,
          ...ins,
        ]);
      } catch (e) {
        console.log(e);
      }
    };

    if (miniSwap) {
      fetch();
    }
    return () => {};
  }, [miniSwap]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const val = await miniSwap.methods.getAmountsOut(
          fromAmount(inputValue, inputCurrency.decimals), [
            inputCurrency.address,
            outCurrency.address,
          ]).call();

        setOutValue(toAmount(val, outCurrency.decimals).toLocaleString());
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };

    if (inputValue && miniSwap && inputCurrency && outCurrency) {
      fetch();
    } else if (!inputValue) {
      setOutValue('');
    }
    return () => {};
  }, [
    inputCurrency,
    inputValue,
    miniSwap,
    outCurrency,
  ]);

  const handleOnApprove = useCallback(async () => {
    await onApprove();
    await fetchAllowance();
  }, [
    onApprove,
    fetchAllowance,
  ]);

  const handleSwap = useCallback(async () => {
    setLoading(true);
    setIsSubmitShow(true);
    setSubmitHash('');
    setSubmitError('');
    try {
      let value = new Big(0);
      if (inputCurrency.address === ZERO_ADDRESS) {
        value = fromAmount(inputValue, 18);
      }
      const tx = await miniSwap.methods.swapForToken(
        fromAmount(inputValue, inputCurrency.decimals), [
          inputCurrency.address,
          outCurrency.address,
        ],
      ).send({
        from: account,
        value,
      });

      setSubmitHash(tx.hash);
      setInputValue('');
    } catch (e) {
      console.log(e);
      setSubmitError(e.message);
    } finally {
      setLoading(false);
    }
  }, [
    inputCurrency,
    inputValue,
    miniSwap,
    outCurrency,
    account,
  ]);

  return (
    <>
      { account && user && user.id <= 0 && !referrer ? (
        <PromoteBanner />
      ) : null }

      <AppBody>
        <AppHeader>
          <Trans>Swap</Trans>
        </AppHeader>

        <AppWrapper>
          <AutoColumn gap={ 'sm' }>
            <AutoColumn gap={ '2px' }>
              <CurrencyInputPanel tokens={ inputTokens }
                                  value={ inputValue }
                                  onValueChange={ setInputValue }
                                  loading={ loading }
                                  currency={ inputCurrency }
                                  currencyBalance={ moInputBalance }
                                  onCurrencyChange={ setInputCurrency }
                                  showMaxButton={ true }
                                  inputDisabled={ !inputCurrency }
              />
              <ArrowWrapper>
                <ArrowDown size="16" color={ theme.text2 } />
              </ArrowWrapper>
              <CurrencyInputPanel tokens={ outTokens } value={ outValue }
                                  onValueChange={ setOutValue }
                                  loading={ loading }
                                  currency={ outCurrency }
                                  currencyBalance={ outBalance }
                                  onCurrencyChange={ setOutCurrency }
                                  inputDisabled={ true }
              />
            </AutoColumn>

            { inputCurrency && !inputCurrency.isNative && (
              !allowance || allowance.lte(0)
            ) ? (
              <ButtonLight
                disabled={ approveLoading || !inputCurrency }
                onClick={ handleOnApprove }
              >
                <Trans>Approve</Trans>
                { approveLoading ? <Loader /> : null }
              </ButtonLight>
            ) : (
              <ButtonPrimary
                disabled={ loading || !inputCurrency || !outCurrency ||
                  !inputValue || !outputValueEnough } onClick={ handleSwap }>

                { !outputValueEnough ? <Trans>Not enough liquidity</Trans> :
                  <Trans>Swap</Trans> }

                { loading ? <Loader /> : null }
              </ButtonPrimary>
            ) }
          </AutoColumn>
        </AppWrapper>

        <Modal isOpen={ isSubmitShow }
               onDismiss={ () => setIsSubmitShow(false) }>
          { loading ? (
            <LoadingView onDismiss={ () => setIsSubmitShow(false) } />
          ) : (
            <SubmittedView onDismiss={ () => setIsSubmitShow(false) }
                           hash={ submitHash }>
              { submitError ? (
                submitError
              ) : <Trans>Swap Succeeded.</Trans> }
            </SubmittedView>
          ) }
        </Modal>
      </AppBody>
    </>
  );
}

export default Swap;
