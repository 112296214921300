import styled from 'styled-components/macro';
import { ThemedText } from '../../theme';
import { RowBetween, RowFixed } from '../Row';

const StyledSwapHeader = styled.div`
  padding: 1rem 1.25rem 0.5rem 1.25rem;
  width: 100%;
  color: ${ ({ theme }) => theme.text2 };
`;

export default function AppHeader({
  children,
  rightContent = null,
  leftContent = null,
}) {
  return (
    <StyledSwapHeader>
      <RowBetween>
        { leftContent ? (
          <RowFixed>
            { leftContent }
          </RowFixed>
        ) : null }
        <RowFixed>
          <ThemedText.Black fontWeight={ 500 } fontSize={ 16 }>
            { children }
          </ThemedText.Black>
        </RowFixed>

        <RowFixed>
          { rightContent }
        </RowFixed>
      </RowBetween>
    </StyledSwapHeader>
  );
}

export const AppWrapper = styled.div`
  position: relative;
  padding: 20px;
`;

