import Modal from '../../../components/Modal';
import ModalWrapper from '../../../components/ModalWrapper';
import { Trans } from '@lingui/macro';
import { AutoColumn } from '../../../components/Column';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import NumericalInput from '../../../components/NumericalInput';
import Row from '../../../components/Row';
import { useWallet } from 'use-wallet';
import {
  ButtonLight, ButtonPrimary,
} from '../../../components/Button';
import Loader from '../../../components/Loader';
import { fromAmount, toAmount } from '../../../utils/amount';
import Big from 'big.js';
import Countdown, { zeroPad } from 'react-countdown';

const InputText = styled(NumericalInput)`
  width: 100%;
  border-radius: 24px;
  padding: 8px 12px;
`;

const LabelWrapper = styled.div`
  font-size: 14px;
`;

const Symbol = styled.div`
  margin-left: 8px;
`;

const BalanceWrapper = styled.div`
  font-size: 14px;
`;

const NoteWrapper = styled.div`
  font-size: 12px;
  color: ${ ({ theme }) => theme.text3 };
  text-align: center;
`;

function PoolWithdrawModal({
  isOpen,
  setIsOpen,
  depositToken,
  pool,
  miniPool,
  userInfo,
  pending
}) {

  const { account } = useWallet();

  const [loading, setLoading] = useState(false);
  const [harvestLoading, setHarvestLoading] = useState(false);
  const [value, setValue] = useState('');

  const balance = useMemo(() => {
    if (!userInfo) {
      return new Big(0);
    }
    return new Big(userInfo.amount);
  }, [userInfo]);

  const handleOnWithdraw = useCallback(async () => {
    setLoading(true);
    try {
      console.log("value depositToken.decimals",value,depositToken.decimals)
      console.log(fromAmount(value, depositToken.decimals));
      console.log("pool",pool);
      console.log("userInfo",userInfo);

      const tx = await miniPool.methods.withdraw(pool.id,
        fromAmount(value, depositToken.decimals)
      ).send({ from: account });
      //.call({ from: account });
        //send({ from: account });
        console.log("value")
        console.log(fromAmount(value, depositToken.decimals));
      console.log(tx);

      setIsOpen(false);

    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

  }, [
    account,
    depositToken,
    miniPool,
    pool,
    setIsOpen,
    value,
  ]);

  const handleOnHarvest = useCallback(async () => {
    setHarvestLoading(true);
    try {
      const tx = await miniPool.methods.withdraw(pool.id,
        fromAmount(0, depositToken.decimals),
      ).send({ from: account });

      console.log(tx);
    } catch (e) {
      console.log(e);
    } finally {
      setHarvestLoading(false);
    }

  }, [
    account,
    depositToken,
    miniPool,
    pool,
  ]);

  const endTime = useMemo(() => {
    return pool.endTime * 1000;
  }, [
    pool,
  ]);

  // Renderer callback with condition
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      return <div><Trans>Over</Trans></div>;
    } else {
      return (
        <span>
          { days > 0 ? `${ zeroPad(days) }:` : null }
          { zeroPad(hours) }:{ zeroPad(minutes) }:{ zeroPad(seconds) }
        </span>
      );
    }
  };

  return (
    <Modal isOpen={ isOpen } onDismiss={ () => setIsOpen(false) }>
      { depositToken ? (
        <ModalWrapper title={ <Trans>Withdraw</Trans> }
                      toggleWalletModal={ () => setIsOpen(false) }>
          <AutoColumn gap={ '24px' }>
            <AutoColumn gap={ 'sm' }>
              <LabelWrapper>
                <Trans>Withdraw Amount</Trans>
              </LabelWrapper>

              <Row>
                <InputText value={ value }
                           onUserInput={ setValue } />
                <Symbol>{ depositToken.symbol }</Symbol>
              </Row>

              <BalanceWrapper>
                <Trans>Balance: { toAmount(balance, depositToken.decimals).
                  toLocaleString() }</Trans> { depositToken.symbol }
              </BalanceWrapper>
            </AutoColumn>

            <ButtonPrimary
              onClick={ handleOnWithdraw }
              disabled={ loading || !value || value <= 0 || Date.now() <
                endTime }>
              { loading ? <Loader /> : <Trans>Withdraw</Trans> }
              { Date.now() < endTime ? (
                <Countdown
                  date={ endTime }
                  renderer={ renderer }
                />
              ) : null }
            </ButtonPrimary>

            {
              pending.gt(0) ? (
                <ButtonLight
                  onClick={ handleOnHarvest }
                  disabled={ harvestLoading }>
                  { harvestLoading ? <Loader /> : <Trans>Harvest</Trans> }
                </ButtonLight>
              ): null
            }

            <NoteWrapper>
              <Trans>The reward will be returned together with the
                withdrawal.</Trans>
            </NoteWrapper>
          </AutoColumn>
        </ModalWrapper>
      ) : null }
    </Modal>
  );
}

export default PoolWithdrawModal;
