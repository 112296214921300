import styled from 'styled-components';
import CurrencyLogo from '../../../components/CurrencyLogo';
import Column from '../../../components/Column';
import { ThemedText } from '../../../theme';
import { toSpecialAmount } from '../../../utils/amount';
import { useCallback, useEffect, useState } from 'react';
import Big from 'big.js';
import { ButtonEmpty } from '../../../components/Button';
import Loader from '../../../components/Loader';
import { useWallet } from 'use-wallet';
import { Trans } from '@lingui/macro';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const ListItemName = styled(ThemedText.DarkGray)`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 300;
  font-size: 12px;
`;

const TokenItemWrapper = styled.div`
  padding: 4px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0px, 72px);
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: rgb(237, 238, 242);
  }
`;

const Price = styled.span`
  font-size: 14px;
`;

const ListItemValue = styled.div`
  justify-self: flex-end;
`;

const SelectionText = styled.div`
  font-size: 14px;
  color: ${ ({ theme }) => theme.text3 };
`;

function Item({
  currency,
  miniPool,
}) {
  const { account } = useWallet();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(new Big(0));

  const fetch = useCallback(async () => {
    try {
      const val = await miniPool.methods.rewards(currency.address, account).
        call();
      setValue(new Big(val));
    } catch (e) {
      console.log(e);
    }
  }, [
    account,
    currency,
    miniPool,
  ]);

  const handleClaim = useCallback(async () => {
    setLoading(true);
    try {
      await miniPool.methods.claim(currency.address).send({ from: account });
      await fetch();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [
    account,
    fetch,
    currency,
    miniPool,
  ]);

  useEffect(() => {
    if (miniPool && currency && account) {
      fetch();
    }

    return () => {};
  }, [
    fetch,
    miniPool,
    currency,
    account,
  ]);

  return (
    <TokenItemWrapper>
      <CurrencyLogo
        size={ '24px' }
        src={ currency.icon } />
      <Column>
        <div>{ currency.symbol }</div>
        <ListItemName>
          { currency.name }
        </ListItemName>
      </Column>

      <Price>
        { toSpecialAmount(value, currency.decimals) }
      </Price>

      <ListItemValue>
        <ButtonEmpty padding={ '0' } disabled={ loading || value.lte(0) }
                     onClick={ handleClaim }>
          <Trans>Receive</Trans> { loading ? <Loader /> : null }
        </ButtonEmpty>
      </ListItemValue>
    </TokenItemWrapper>
  );
}

function PoolClaimItems({
  tokens,
  miniPool,
}) {
  return (
    <Wrapper>
      <SelectionText>
        <Trans>Pool Claim</Trans>
      </SelectionText>
      { tokens.map(
        currency => <Item key={ currency.address } miniPool={ miniPool }
                          currency={ currency } />) }
    </Wrapper>
  );
}

export default PoolClaimItems;
