import React, {
  createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useMiniRegister } from '../../hooks/useContract';
import {
  MINI_REGISTER_ADDRESS, MINI_SWAP_ADDRESS,
} from '../../constants/address';
import { useWallet } from 'use-wallet';
import { ZERO_ADDRESS } from '../../utils/ethereum';

export const Context = createContext({});

const UserStateContext = ({ children }) => {
  const [user, setUser] = useState(null);
  const {
    account,
    getBlockNumber,
  } = useWallet();
  const blockNumber = getBlockNumber();
  const miniRegister = useMiniRegister(MINI_REGISTER_ADDRESS);

  const referrer = useMemo(() => {
    if (user) {
      if (user.referrer === ZERO_ADDRESS) {
        return null;
      }
      return user.referrer;
    }
    return null;
  }, [user]);

  const counts = useMemo(() => {
    if (user) {
      return user.counts;
    }
    return 0;
  }, [user]);

  const handleRegister = useCallback(async (ref) => {
    try {
      await miniRegister.methods.register(ref).send({ from: account });
    } catch (e) {
      console.log(e);
    }
  }, [
    miniRegister,
    account,
  ]);

  const fetchUser = useCallback(async () => {
    try {
      const data = await miniRegister.methods.accounts(account).call();
      //  add api 
      setUser(data);
    } catch (e) {
      console.log(e);
    }
  }, [
    account,
    miniRegister,
  ]);

  useEffect(() => {
    if (account && miniRegister) {
      fetchUser();
    }
    return () => {};
  }, [
    account,
    blockNumber,
    fetchUser,
    miniRegister,
  ]);

  return (
    <Context.Provider value={ {
      user,
      counts,
      referrer,
      fetchUser,
      onRegister: handleRegister,
    } }>
      { children }
    </Context.Provider>
  );
};

export default UserStateContext;
