import { AutoColumn } from '../../../components/Column';
import styled from 'styled-components';
import { shortenAddress } from '../../../utils';
import { toAmount } from '../../../utils/amount';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useWallet } from 'use-wallet';
import Loader from '../../../components/Loader';
import { ButtonEmpty } from '../../../components/Button';

const ItemWrapper = styled.div`
  padding: 12px 4px;
  display: grid;
  row-gap: 4px;
  font-size: 14px;
`;

const HR = styled.div`
  width: 100%;
  border-bottom: 1px dashed #eaeaea;
`;

function Item({
  miniPool,
  pool,
}) {
  const { account } = useWallet();
  const [rateLoading, setRateLoading] = useState(false);

  const handleSetRate = useCallback(async () => {
    setRateLoading(true);
    try {
      const r = prompt('设置返利比例：请输入返利比例，输入0-1000的数字，表示千分之几');

      if (r) {
        await miniPool.methods.setPoolAwardRate(pool.id, r).
          send({ from: account });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRateLoading(false);
    }
  }, [
    pool,
    miniPool,
    account,
  ]);

  return (
    <>
      <ItemWrapper>
        <div>ID: { pool.id }</div>
        <div>抵押币种：{ shortenAddress(pool.pledgeToken) }</div>
        <div>奖励币种：{ shortenAddress(pool.token) }</div>
        <div>已抵押：{ pool.supply }</div>
        <div>每区块发行：{ toAmount(pool.tokenPerBlock, 18).toLocaleString() }</div>
        <div>上次结算区块：{ pool.lastRewardBlock }</div>
        <div>结束区块：{ pool.endRewardBlock }</div>
        <div>推广奖励比例1：{ pool.awardRate }/1000</div>
        <div>推广奖励比例2：{ pool.awardRate2 }/1000</div>
        <div>可提现时间：{ moment(pool.endTime * 1000).toLocaleString() }</div>
        <ButtonEmpty onClick={ handleSetRate }
                     disabled={ rateLoading }>
          { rateLoading ? <Loader /> : '设置推广奖励比例1' }
        </ButtonEmpty>
      </ItemWrapper>

      <HR />
    </>
  );
}

function PoolItems({
  miniPool,
  pools,
}) {
  return (
    <div>
      <AutoColumn gap={ '8px' }>
        { pools.map(pool => <Item miniPool={ miniPool } key={ pool.id }
                                  pool={ pool } />) }
      </AutoColumn>
    </div>
  );
}

export default PoolItems;
