import Modal from '../Modal';
import ModalWrapper from '../ModalWrapper';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';
import { useCallback, useMemo, useState } from 'react';
import CurrencyLogo from '../CurrencyLogo';
import Column from '../Column';
import { ThemedText } from '../../theme';
import { isAddress } from 'ethers/lib/utils';
import useTokens from '../../hooks/useTokens';
import Loader from '../Loader';
import { useWallet } from 'use-wallet';
import { toSpecialAmount } from '../../utils/amount';
import useERC20Balance from '../../hooks/useERC20Balance';

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
`;

const SearchInput = styled.input`
  position: relative;
  display: flex;
  padding: 16px;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  background: none;
  outline: none;
  border-radius: 20px;
  color: rgb(0, 0, 0);
  border: 1px solid rgb(206, 208, 217);
  appearance: none;
  font-size: 18px;
  transition: border 100ms ease 0s;
`;

const TokenListWrapper = styled.div`
  flex: 1 1 0;
  position: relative;
  margin-top: 1rem;
`;

const TokenList = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
  will-change: transform;
  direction: ltr;
`;

const TokenItemWrapper = styled.div`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto minmax(0px, 72px);
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    background-color: rgb(237, 238, 242);
  }
`;

const ListItemName = styled(ThemedText.DarkGray)`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 300;
  font-size: 12px;
`;

const ListItemValue = styled.div`
  justify-self: flex-end;
`;

const NoDataWrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  color: ${ ({ theme }) => theme.text3 };
`;

function TokenItem({
  currency,
  onClick,
}) {

  const {
    account,
    balance,
  } = useWallet();

  const value = useERC20Balance(currency.contract, account);

  return (
    <TokenItemWrapper onClick={ onClick }>
      <CurrencyLogo
        size={ '24px' }
        src={ currency.icon } />
      <Column>
        <div>{ currency.symbol }</div>
        <ListItemName>{ currency.name }</ListItemName>
      </Column>

      <span />
      <ListItemValue>
        { currency.isNative ? (
          toSpecialAmount(balance, currency.decimals)
        ) : (
          toSpecialAmount(value, currency.decimals)
        ) }
      </ListItemValue>
    </TokenItemWrapper>
  );
}

function TokenSelectorModal({
  isOpen,
  onDismiss,
  tokens,
  onSelect,
}) {
  const [value, setValue] = useState('');

  const handleSearchInput = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const inputToken = useMemo(() => {
    if (isAddress(value)) {
      return [value];
    }
    return [];
  }, [value]);

  const [inputTokens, loading] = useTokens(inputToken);

  const filterTokens = useMemo(() => {
    if (value) {
      if (isAddress(value)) {
        return inputTokens;
      }
      const k = value.toLocaleLowerCase();

      return tokens.filter((e) => {
        return e.name.toLowerCase().indexOf(k) >= 0 ||
          e.symbol.toLowerCase().indexOf(k) >= 0;
      });
    }
    return tokens;
  }, [
    tokens,
    value,
    inputTokens,
  ]);

  const handleOnSelect = useCallback((e) => {
    onSelect(e);
    onDismiss();
  }, [
    onSelect,
    onDismiss,
  ]);

  const renderList = (token) => {
    return (
      <TokenList>
        { filterTokens.map(e => <TokenItem key={ e.address } currency={ e }
                                           onClick={ () => handleOnSelect(
                                             e) } />) }
        { loading ? (
          <Loader />
        ) : (
          !filterTokens.length ? (
            <NoDataWrapper>
              <Trans>No Data</Trans>
            </NoDataWrapper>
          ) : null
        ) }
      </TokenList>
    );
  };

  return (
    <Modal isOpen={ isOpen } onDismiss={ onDismiss } minHeight={ false }
           maxHeight={ 90 }>
      <ModalWrapper toggleWalletModal={ onDismiss }
                    title={ <Trans>Select Token</Trans> }>
        <InputWrapper>
          <SearchInput value={ value }
                       placeholder={ t`Search for a name or paste an address` }
                       onChange={ handleSearchInput } />
        </InputWrapper>

        <TokenListWrapper>
          { renderList() }
        </TokenListWrapper>
      </ModalWrapper>
    </Modal>
  );
}

export default TokenSelectorModal;
