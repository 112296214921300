import styled from 'styled-components';
import { ArrowRight, Gift } from 'react-feather';
import { RowBetween } from '../Row';
import { Trans, t } from '@lingui/macro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '../Modal';
import ModalWrapper from '../ModalWrapper';
import { ButtonPrimary } from '../Button';
import { AutoColumn } from '../Column';
import { isAddress } from 'ethers/lib/utils';
import { getReferrerFromLocal } from '../../utils/referrer';
import { useWallet } from 'use-wallet';
import { ZERO_ADDRESS } from '../../utils/ethereum';
import Loader from '../Loader';
import useUserState from '../../hooks/useUserState';

const Wrapper = styled.div`
  padding: 1em;
  max-width: ${ ({ maxWidth }) => maxWidth ?? '480px' };
  width: 100%;
  border-radius: 12px;
  background-repeat: repeat;
  background-color: ${ ({ theme }) => theme.bg0 };
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='64' viewBox='0 0 48 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 28v-4L36 12 24 24 12 12 0 24v4l4 4-4 4v4l12 12 12-12 12 12 12-12v-4l-4-4 4-4zM8 32l-6-6 10-10 10 10-6 6 6 6-10 10L2 38l6-6zm12 0l4-4 4 4-4 4-4-4zm12 0l-6-6 10-10 10 10-6 6 6 6-10 10-10-10 6-6zM0 16L10 6 4 0h4l4 4 4-4h4l-6 6 10 10L34 6l-6-6h4l4 4 4-4h4l-6 6 10 10v4L36 8 24 20 12 8 0 20v-4zm0 32l10 10-6 6h4l4-4 4 4h4l-6-6 10-10 10 10-6 6h4l4-4 4 4h4l-6-6 10-10v-4L36 56 24 44 12 56 0 44v4z' fill='%23E8006F' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

const AddressInput = styled.input`
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid ${ ({ theme }) => theme.bg2 };
`;

const IconWrapper = styled.div`
  color: ${ ({ theme }) => theme.primary1 };
`;

function PromoteBanner() {
  const { account } = useWallet();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    referrer,
    onRegister,
  } = useUserState();

  const [value, setValue] = useState(
    getReferrerFromLocal() === ZERO_ADDRESS ? '' : getReferrerFromLocal());

  const isDisabled = useMemo(() => {
    return !account || !value || account.toLocaleLowerCase() ===
      value.toLocaleLowerCase();

  }, [
    value,
    account,
  ]);

  useEffect(() => {
    if (referrer) {
      setIsOpen(false);
    }
  }, [referrer]);

  const handleOnBind = useCallback(async () => {
    if (isAddress(value)) {
      setLoading(true);

      try {
        await onRegister(value);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }, [
    value,
    onRegister,
  ]);

  return (
    <Wrapper onClick={ () => setIsOpen(true) }>
      <RowBetween>
        <div>
          <IconWrapper>
            <Gift />
          </IconWrapper>
          <div>
            <Trans>Promote to get about 3%~10% extra income.</Trans>
          </div>
        </div>

        <div>
          <ArrowRight />
        </div>
      </RowBetween>

      <Modal isOpen={ isOpen } onDismiss={ () => setIsOpen(false) }>
        <ModalWrapper title={ <Trans>Bind</Trans> }
                      toggleWalletModal={ () => setIsOpen(false) }>
          <AutoColumn gap={ '16px' }>
            <div>
              <Trans>Your Superior Address</Trans>
            </div>
            <AddressInput
              value={ value }
              onChange={ e => setValue(e.target.value) }
              placeholder={ t`Please enter the superior address.` } />

            <ButtonPrimary onClick={ handleOnBind }
                           disabled={ isDisabled || loading }>
              { loading ? <Loader /> : <Trans>Bind</Trans> }
            </ButtonPrimary>
          </AutoColumn>
        </ModalWrapper>
      </Modal>
    </Wrapper>
  );
}

export default PromoteBanner;
