import Header from './components/Header';
import styled from 'styled-components';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import Polling from './components/Header/Polling';
import Loader from './components/Loader';
import Swap from './pages/Swap';
import Pools from './pages/Pools';
import Claim from './pages/Claim';
import Console from './pages/Console';
import useSetReferrerFromUrl from './hooks/useSetReferrerFromUrl';
import SwapConsole from './pages/SwapConsole';
import PoolsConsole from './pages/PoolsConsole';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

const HeaderWrapper = styled.div`
  ${ ({ theme }) => theme.flexRowNoWrap }
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${ ({ theme }) => theme.mediaWidth.upToSmall`
    padding: 4rem 8px 16px 8px;
  ` };
`;

function App() {
  useSetReferrerFromUrl();

  return (
    <AppWrapper>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>

      <BodyWrapper>
        <Polling />
        <Suspense fallback={ <Loader size={ '32px' } /> }>
          <Routes>
            <Route exact path="/" element={ <Navigate to="/swap" /> } />
            <Route path="/swap" element={ <Swap /> } />
            <Route path="/pools" element={ <Pools /> } />
            <Route path="/console/pools" element={ <PoolsConsole /> } />
            <Route path="/claim" element={ <Claim /> } />
            <Route path="/console" element={ <Console /> } />
            <Route path="/console/swap" element={ <SwapConsole /> } />
          </Routes>
        </Suspense>
      </BodyWrapper>
    </AppWrapper>
  );
}

export default App;
