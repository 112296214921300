import { css } from 'styled-components/macro';
import styled from 'styled-components';

export const loadingOpacityMixin = css`
  filter: ${ ({ $loading }) => (
          $loading ? 'grayscale(1)' : 'none'
  ) };
  opacity: ${ ({ $loading }) => (
          $loading ? '0.4' : '1'
  ) };
  transition: opacity 0.2s ease-in-out;
`;

export const LoadingOpacityContainer = styled.div`
  ${ loadingOpacityMixin }
`;
