import {
  ButtonEmpty,
} from '../../../components/Button';
import { useCallback, useState } from 'react';
import Loader from '../../../components/Loader';
import { getERC20Contract, ZERO_ADDRESS } from '../../../utils/ethereum';
import useWeb3 from '../../../hooks/useWeb3';
import { useWallet } from 'use-wallet';
import { fromAmount } from '../../../utils/amount';

function WithdrawButton({ miniSwap }) {
  const web3 = useWeb3();
  const { account } = useWallet();
  const [loading, setLoading] = useState(false);

  const handleOnClick = useCallback(async () => {
    setLoading(true);
    try {
      const tokenAddress = prompt('输入代币地址');

      if (!tokenAddress) {
        return;
      }

      let decimals = 18;
      if (tokenAddress !== ZERO_ADDRESS) {
        const token = getERC20Contract(tokenAddress, web3);
        decimals = await token.methods.decimals().call();
      }

      const amount = prompt('输入提出数量');

      const tx = await miniSwap.methods.withdrawStuckTokens(
        tokenAddress, account, fromAmount(amount, parseInt(decimals))).
        send({ from: account });

      console.log(tx);

    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

  }, [
    miniSwap,
    web3,
    account,
  ]);

  return (
    <ButtonEmpty padding={ '0' } onClick={ handleOnClick } disabled={ loading }>
      { loading ? <Loader /> : null } 管理员提币操作
    </ButtonEmpty>
  );
}

export default WithdrawButton;
