import AppBody from '../AppBody';
import AppHeader, { AppWrapper } from '../../components/AppView';
import { Trans } from '@lingui/macro';
import { AutoColumn } from '../../components/Column';
import { ChevronRight } from 'react-feather';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ConsoleItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  color: ${ ({ theme }) => theme.text1 };
  text-decoration: none;

  &:hover {
    background-color: rgb(237, 238, 242);
  }
`;

function Console() {
  return (
    <AppBody>
      <AppHeader>
        <Trans>Console</Trans>
      </AppHeader>

      <AppWrapper>
        <AutoColumn gap={ '24px' }>
          <ConsoleItem to={ '/console/swap' }>
            <div><Trans>Swap</Trans>配置</div>
            <ChevronRight />
          </ConsoleItem>
        </AutoColumn>

        <AutoColumn gap={ '24px' }>
          <ConsoleItem to={ '/console/pools' }>
            <div><Trans>Pools</Trans>配置</div>
            <ChevronRight />
          </ConsoleItem>
        </AutoColumn>
      </AppWrapper>
    </AppBody>
  );
}

export default Console;
