import styled from 'styled-components';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 25vh;
`;

const TextWrapper = styled.div`
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  font-weight: 400;
  font-size: 16px;
  color: rgb(110, 114, 125);
  text-align: center;

  svg {
    margin-bottom: 0.5rem;
  }
`;

function NoDataContent({
  icon,
  text,
}) {
  return (
    <Wrapper>
      <TextWrapper>
        { icon }
        <div>
          { text }
        </div>
      </TextWrapper>
    </Wrapper>
  );
}

export default NoDataContent;
