import Modal from '../../../components/Modal';
import ModalWrapper from '../../../components/ModalWrapper';
import { Trans } from '@lingui/macro';
import { AutoColumn } from '../../../components/Column';
import { useCallback, useMemo,useEffect, useState } from 'react';
import styled from 'styled-components';
import NumericalInput from '../../../components/NumericalInput';
import Row from '../../../components/Row';
import useERC20Allowance from '../../../hooks/useERC20Allowance';
import {
  MINI_POOL_ADDRESS,
} from '../../../constants/address';
import useERC20Approve from '../../../hooks/useERC20Approve';
import { useWallet } from 'use-wallet';
import { ButtonLight, ButtonPrimary } from '../../../components/Button';
import Loader from '../../../components/Loader';
//import useERC20Balance from '../../../hooks/useERC20Balance';
import { fromAmount, toAmount } from '../../../utils/amount';
import Big from 'big.js';

const InputText = styled(NumericalInput)`
  width: 100%;
  border-radius: 24px;
  padding: 8px 12px;
`;

const LabelWrapper = styled.div`
  font-size: 14px;
`;

const Symbol = styled.div`
  margin-left: 8px;
`;

const BalanceWrapper = styled.div`
  font-size: 14px;
`;

function PoolProfitModal({
  isOpen,
  setIsOpen,
  depositToken,
  userInfo,
  pool,
  miniPool,
}) {

  const { account } = useWallet();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [profitBalance, setProfitBalance] = useState(new Big(0));
  



  const isLimit = useMemo(() => {
    if (userInfo && value && depositToken  && pool ) {
      const _amount = new Big(fromAmount(value, depositToken.decimals))
      return _amount.gt(profitBalance);
    }
    return false;
  }, [
    userInfo,
    value,
    profitBalance,
    pool,
    depositToken,
  ]);

  useEffect( () => {
    
    async function fetchData() {
    const res = await miniPool.methods.pendingToken(pool.id,account).call();
    const resvalue =new Big(res)
    const p1 = resvalue.mul(pool.awardRate).div(1000);
    const p2 = resvalue.mul(pool.awardRate2).div(1000);
    setProfitBalance(resvalue.sub(p1).sub(p2).sub(userInfo.earnedPoolRewards));
    }
    fetchData();
  }, [
    miniPool,
    account,
    pool,
   
  ]);

  

  // const balance = useERC20Balance(tokenContract, account);

  const handleOnIncomeWithdraw = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await miniPool.methods.incomeWithdraw(pool.id,
        fromAmount(value, depositToken.decimals)
      ).send({ from: account });


      console.log(tx);

      setIsOpen(false);

    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

  }, [
    miniPool,
    pool,
    value,
    depositToken,
    account,
    setIsOpen,
  ]);

  return (
    <Modal isOpen={ isOpen } onDismiss={ () => setIsOpen(false) }>
      { depositToken ? (
        <ModalWrapper title={ <Trans>Profit</Trans> }
                      toggleWalletModal={ () => setIsOpen(false) }>
          <AutoColumn gap={ '24px' }>
            <AutoColumn gap={ 'sm' }>
              <LabelWrapper>
                <Trans>Profit  Amount</Trans>
              </LabelWrapper>

              <Row>
                <InputText value={ value }
                           onUserInput={ setValue } />
                <Symbol>{ depositToken.symbol }</Symbol>
              </Row>

              <BalanceWrapper>
                <Trans>Balance: { toAmount(profitBalance, depositToken.decimals).
                  toFixed(4, 0)  }</Trans> { depositToken.symbol }
                  
              </BalanceWrapper>
              <BalanceWrapper>

               <Trans>EarnedRewards: { toAmount(userInfo.earnedPoolRewards, depositToken.decimals).
                  toFixed(4, 0)  }</Trans> { depositToken.symbol }
                  
              </BalanceWrapper>
            </AutoColumn>

            { 
              <ButtonPrimary
                onClick={ handleOnIncomeWithdraw }
                disabled={ loading || !value || value <= 0 || isLimit }>
                { loading ? <Loader /> : 
                    <Trans>Profit</Trans>
                }
              </ButtonPrimary>
             }
          </AutoColumn>
        </ModalWrapper>
      ) : null }
    </Modal>
  );
}

export default PoolProfitModal;
