import React from 'react';
import styled from 'styled-components/macro';

import logo from '../../assets/images/token-logo.png';

const StyledNativeLogo = styled.img`
  width: ${ ({ size }) => size };
  height: ${ ({ size }) => size };
  background: radial-gradient(white 50%, #ffffff00 calc(75% + 1px), #ffffff00 100%);

  border-radius: 50%;
  box-shadow: 0 0 1px white;
  border: 1px solid rgba(255, 255, 255, 0);
`;

export default function CurrencyLogo({
  src,
  size,
  ...rest
}) {

  const onError = (e) => {
    e.target.src = logo;
  };

  return (
    <StyledNativeLogo src={ src } size={ size }
                      onError={ onError } { ...rest } />
  );
}
